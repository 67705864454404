import React, { useMemo } from 'react';
import ReactQuill from 'react-quill';

export default function ImageQuill({ name, value, onChange, placeholder, disabled, required }) {
  const formats = [
    "align",
    "bold",
    "code-block",
    "color",
    "float",
    "height",
    "image",
    "italic",
    "link",
    "list",
    "placeholder",
    "calltoaction",
    "size",
    "underline",
    "width"
  ];
  const modules = useMemo(() => ({
    imageActions: {},
    imageFormats: {},
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true
    },
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
      ["link", "image"],
    ]
  }), []);
  // Handle s3 upload
  // const imageHandler = () => {
  //   const quill = this?.quill; // Get the Quill instance
  //   const range = quill?.getSelection();

  //   const input = document.createElement('input')
  //   input.setAttribute('type', 'file')
  //   input.click()
  //   input.onchange = async () => {
  //     const file = input.files[0]
  //     try {
  //       // Upload images to s3
  //       const profileImage = new FormData();
  //       let imageLocation;
  //       profileImage.append('files', file);
  //       profileImage.append('base_key', DOCUMENT_HASH_BASE_KEYS.CMS);
  //       const result = await fileUploads(profileImage);
  //       if (result?.success) {
  //         imageLocation = result?.response?.data?.data?.responses[0]?.actual_location;
  //         const imageUrl = `${getS3BaseUrl()}${imageLocation}`;
  //         console.log({ imageUrl });
  //         if (imageLocation) {
  //           // Insert the image URL into the editor at the current cursor position
  //           quill?.insertEmbed(range.index, 'image', imageUrl);
  //           // setFieldValue('image', imageUrl);
  //         }
  //       } else {
  //         return false;
  //       }
  //     } catch (err) {
  //       console.log('Storage err: ', err)
  //     }
  //   }
  // }

  return (
  <ReactQuill
    formats={formats}
    modules={modules}
    theme="snow"
    name={name}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    readOnly={disabled}
    required={required}
    style={{
      ...(disabled &&
        { cursor: 'not-allowed' })
    }}
  />)
}
