import React, { useState } from 'react';
import {
  Button
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { IconButton, TextField } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { editCMS } from '../../../api/GlobalSettings/api';
import ImageQuill from './ImageQuill';

export default function Home({ page, tabDetails, refresh }) {
  const [isLoading, setIsLoading] = useState(false);
  const [enableField, setEnabledField] = useState({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      main_banner_texts: tabDetails?.main_banner_texts,
      sub_banner: tabDetails?.sub_banner,
      usp_cards: tabDetails?.usp_cards,
      testimonials: tabDetails?.testimonials,
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      main_banner_texts: Yup.array().of(Yup.string().required('header is required')).optional(),
      sub_banner: Yup.string().required(),
      usp_cards: Yup.array().of(Yup.string().required()).optional(),
      testimonials: Yup.array().of(Yup.object({
        content: Yup.string().required('content is required'),
        name: Yup.string().required('name is required'),
        role: Yup.string().required('role is required'),
      })).optional(),
    }),
    onSubmit: async (payload) => {
      try {
        setIsLoading(true)
        const success = await editCMS({ page, ...payload });
        if (success)
          refresh();
      } catch (error) {
        console.log(error);
      }
      finally {
        setIsLoading(false)
      }
    },
  });

  function arrUpdate(fieldName, index, newVal) {
    const updatedVal = formik.values[fieldName].map((item, i) => (i === index) ? newVal : item);
    if (updatedVal[index] != formik.getFieldProps(fieldName).value[index])
      formik.setFieldValue(fieldName, updatedVal)
  }

  function arrDelete(fieldName, index) {
    const updatedVal = formik.values[fieldName].filter((item, i) => (i !== index));
    formik.setFieldValue(fieldName, updatedVal)
  }

  // Add values to formik arrays
  function arrAdd(fieldName) {
    formik.setFieldValue(fieldName, [...formik.values[fieldName], '']);
    setEnabledField((prev) => ({ ...prev, ['main_banner_text-' + (formik.values[fieldName].length - 1)]: false }))
    setEnabledField((prev) => ({ ...prev, ['main_banner_text-' + formik.values[fieldName].length]: !enableField['main_banner_text-' + formik.values[fieldName].length] }))
  }

  const handleSubmit = async () => {
    formik.handleSubmit();
  }

  return (
    <>
      <div>
        <div className='d-flex justify-content-between align-items-center'>
          <h4 style={{ fontWeight: '600', fontSize: '18px' }}>
            Main Banner Section
          </h4>
          <Button
            className="yellow_gradient_btn"
            onClick={handleSubmit}
            disabled={!formik.isValid || isLoading}
          >Save</Button>
        </div>
        {/* Animatic Header */}
        <div className='mt-4'>
          <div className="d-flex mt-3 flex-wrap justify-content-between align-items-center mb-2">
            <h4 style={{ fontWeight: '600', fontSize: '16px' }}>
              Animatic Header
            </h4>
            <div>
              <Button
                type="button"
                color="primary"
                onClick={() =>
                  arrAdd('main_banner_texts')}
              >
                <span><i className="mdi mdi-plus" /></span>
                <span>Animatic Header</span>
              </Button>
            </div>
          </div>

          {formik.values.main_banner_texts?.length > 0 &&
            formik.values.main_banner_texts.map((item, index) =>
            (<div key={index} className='mb-3'>
              <div className='d-flex align-items-center'>
                <TextField
                  label={`Animatic Header #${index + 1}`}
                  name={`main_banner_text-${index}`}
                  value={item}
                  fullWidth
                  type="text"
                  onChange={(e) => arrUpdate('main_banner_texts', index, e.target.value)}
                  onBlur={formik.handleBlur}
                  variant="outlined"
                  size="small"
                  multiline
                  disabled={!enableField[`main_banner_text-${index}`]}
                  error={
                    !!(formik?.touched?.main_banner_texts && formik?.errors?.main_banner_texts)
                  }
                  helperText={formik.errors.main_banner_texts?.length > 0 && <p className='error'>{formik.errors.main_banner_texts.filter((e, i) => (i == index))}</p>}
                />
                <IconButton
                  // key={index}
                  size='small'
                  variant='contained'
                  onClick={(prev) => {
                    setEnabledField({ ...prev, ['main_banner_text-' + index]: !enableField['main_banner_text-' + index] })
                  }}
                >
                  {!enableField['main_banner_text-' + index] ?
                    <EditOutlinedIcon />
                    : <CancelOutlinedIcon />
                  }
                </IconButton>
                <IconButton
                  // key={index}
                  size='small'
                  variant='contained'
                  onClick={() => arrDelete('main_banner_texts', index)}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </div>
            </div>
            )
            )}
        </div>

        {/* Sub Header */}
        <div className='mt-5'>
          <h4 style={{ fontWeight: '600', fontSize: '16px' }}>
            Sub Header
          </h4>
          <div className='d-flex align-items-center'>
            <TextField
              fullWidth
              label='Enter sub banner text'
              name="sub_banner"
              type="text"
              value={formik.values.sub_banner}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              variant="outlined"
              size="small"
              disabled={!enableField?.sub_banner}
              error={
                !!(formik?.touched?.sub_banner && formik?.errors?.sub_banner)
              }
              helperText={
                formik?.touched?.sub_banner && formik?.errors?.sub_banner
                  ? formik?.errors?.sub_banner
                  : ''
              }
            />
            <IconButton
              size='small'
              variant='contained'
              onClick={(prev) => setEnabledField({ ...prev, sub_banner: !enableField?.sub_banner })}
            >
              {!enableField?.sub_banner ?
                <EditOutlinedIcon />
                : <CancelOutlinedIcon />
              }
            </IconButton>
          </div>
        </div>
      </div>
      <form>
        <div className="d-flex mt-3 flex-wrap justify-content-between align-items-center mb-2">
          <h4 style={{ fontWeight: '600', fontSize: '16px' }}>
            USP Cards
          </h4>
          <div>
            <Button
              type="button"
              color="primary"
              onClick={() => arrAdd('usp_cards')}
            >
              <span><i className="mdi mdi-plus" /></span>
              <span>USP Card</span>
            </Button>
          </div>
        </div>
        <ol type='1' className="mb-2 ps-3 pb-3 card_list">
          {formik.values.usp_cards?.length > 0 &&
            formik.values?.usp_cards?.map((item, index) => (
              <li className="feed-item mb-3" key={index}>
                <div className='d-flex align-items-center'>
                  <ImageQuill
                    name={`usp-${index}`}
                    value={item || ''}
                    onChange={(value) => arrUpdate('usp_cards', index, value)}
                    placeholder={`USP ${index + 1}`}
                    disabled={!enableField[`usp-${index}`]}
                    // disabled={true}
                    required
                  />
                  <div className='d-flex flex-column'>
                    <IconButton
                      // key={index}
                      size='small'
                      variant='contained'
                      onClick={(prev) => {
                        setEnabledField({ ...prev, ['usp-' + index]: !enableField['usp-' + index] })
                      }}
                    >
                      {!enableField['usp-' + index] ?
                        <EditOutlinedIcon />
                        : <CancelOutlinedIcon />
                      }
                    </IconButton>
                    <IconButton
                      // key={index}
                      size='small'
                      variant='contained'
                      onClick={() => arrDelete('usp_cards', index)}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </div>
                </div>
              </li>
            ))}
        </ol>
        <hr className="cstm_hr" />
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
          <h4 style={{ fontWeight: '600', fontSize: '16px' }}>
            Testimonials
          </h4>
          <div>
            <Button
              type="button"
              color="primary"
              onClick={() => arrAdd('testimonials')}
            >
              <span><i className="mdi mdi-plus" /></span>
              <span>Testimonial Cards</span>
            </Button>
          </div>
        </div>

        {formik.values.testimonials.map((item, index) => (
          <ul className="activity-feed mb-2 ps-3 card_list" key={index}>
            <li className="feed-item">
              <label>User Name</label>
              <div className='d-flex align-items-center'>
                <TextField
                  label={`User Name ${index + 1}`}
                  name={`testimonial_user_name-${index}`}
                  className="form-control"
                  value={item.name}
                  fullWidth
                  type="text"
                  onChange={(event) => arrUpdate('testimonials', index, { ...item, name: event.target.value })}
                  onBlur={formik.handleBlur}
                  variant="outlined"
                  size="small"
                  disabled={!enableField[`testimonial_user_name-${index}`]}
                  error={
                    !!(formik?.touched?.testimonials && formik?.errors?.testimonials)
                  }
                  helperText={formik.errors.testimonials?.length > 0 && <p className='error'>{formik.errors.testimonials.filter((e, i) => (i === index))[0]?.name}</p>}
                />
                <IconButton
                  // key={index}
                  size='small'
                  variant='contained'
                  onClick={(prev) => {
                    setEnabledField({ ...prev, ['testimonial_user_name-' + index]: !enableField['testimonial_user_name-' + index] })
                  }}
                >
                  {!enableField['testimonial_user_name-' + index] ?
                    <EditOutlinedIcon />
                    : <CancelOutlinedIcon />
                  }
                </IconButton>
              </div>
            </li>
            <li className="feed-item">
              <label>Role</label>
              <div className='d-flex align-items-center'>
                <TextField
                  label={`User Role ${index + 1}`}
                  name={`testimonial_user_role-${index}`}
                  className="form-control"
                  value={item.role}
                  fullWidth
                  type="text"
                  onChange={(event) => arrUpdate('testimonials', index, { ...item, role: event.target.value })}
                  onBlur={formik.handleBlur}
                  variant="outlined"
                  size="small"
                  disabled={!enableField[`testimonial_user_role-${index}`]}
                  error={
                    !!(formik?.touched?.testimonials && formik?.errors?.testimonials)
                  }
                  helperText={formik.errors.testimonials?.length > 0 && <p className='error'>{formik.errors.testimonials.filter((e, i) => (i === index))[0]?.role}</p>}
                />
                <IconButton
                  // key={index}
                  size='small'
                  variant='contained'
                  onClick={(prev) => {
                    setEnabledField({ ...prev, ['testimonial_user_role-' + index]: !enableField['testimonial_user_role-' + index] })
                  }}
                >
                  {!enableField['testimonial_user_role-' + index] ?
                    <EditOutlinedIcon />
                    : <CancelOutlinedIcon />
                  }
                </IconButton>
              </div>
            </li>
            <li className="feed-item">
              <label>Content</label>
              <ImageQuill
                name={`testimonial_content-${index}`}
                className='mb-0 pb-0'
                value={item.content || ''}
                onChange={(value) => arrUpdate('testimonials', index, { ...item, content: value })}
                placeholder={`Content ${index + 1}`}
                required
              />
            </li>
            <IconButton
              className='w-100'
              // key={index}
              size='small'
              variant='contained'
              onClick={() => arrDelete('testimonials', index)}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </ul>
        ))}
        <hr className="cstm_hr" />
      </form>
    </>
  );
}
