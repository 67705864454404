import React, { useEffect, useState } from 'react';

import {
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from 'reactstrap';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// import images
import logo from '../../assets/images/logo1.png';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { passwordResetWithURL, verifyPasswordResetLink } from '../../api/common';
import Pages404 from '../Utility/pages-404';
import { FormHelperText, IconButton, InputAdornment, OutlinedInput, TextField } from '@mui/material';
import { VisibilityOff } from '@mui/icons-material';
import { Visibility } from '@material-ui/icons';

function VerifyPasswordReset() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [isVerified, setIsVerified] = useState(false);
  document.title = 'Set Password| DStayZ';

  // Password show/hide
  const [showPassword, setShowPassword] = useState({});
  const handleClickShowPassword = (key) => {
    setShowPassword((show) => ({ ...show, [key]: !showPassword[key] }))
  };

  const passwordPattern = /^(?!.*[\s])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,16}$/;
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      password: Yup.string().required('Please Enter Your Password').matches(passwordPattern, "Password should be 8 character long with maximum 16 character of one capital one small one digit and one special case without whitespace"),
      confirmPassword: Yup.string().required('Please Enter Your Password').oneOf([Yup.ref('password')], 'Both the passwords should match. ')
    }),
    onSubmit: async (payload) => {
      try {
        const response = await passwordResetWithURL(token, payload);
        if (response?.data?.data?.success === true) {
          toast.success(response.data.message);
          navigate("/login")
        }
        if (response?.data?.data?.success === false) toast.warning(response.data.message);
      } catch (e) {
        if (e.response?.data?.message) toast.error(e.response?.data?.message)
        console.log(e)
      }
    }
  });
  const validateLink = async () => {
    try {
      const response = await verifyPasswordResetLink(token);
      if (response?.data?.data?.success === true) {
        setIsVerified(true);
        toast.success(response?.data?.message);
      }
      if (response?.data?.data?.success === false) {
        setIsVerified(false);
        toast.warning(response?.data?.message);
      }

    } catch (e) {
      if (e?.response?.data?.message) toast.error(e.response.data.message);
      else toast.error(e?.message);
      console.log(e);
    }
  }
  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    validateLink();
    return function cleanup() {
      document.body.className = '';
    };
  }, []);

  return (
    <div className="account-pages my-5 pt-sm-5">
      {isVerified ? (
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div>
                <div className="card">
                  <Link to="/" className="mt-2 d-block auth-logo">
                    <img src={logo} alt="" height="40" className="logo logo-dark" />
                  </Link>
                  <div className="card-body">

                    <div className="text-center mt-2">
                      <h5 className="text-primary">Set Password</h5>
                      <p className="text-muted">Set Password with DStayZ.</p>
                    </div>

                    <div className="p-2 mt-2">
                      <Form className="form-horizontal" onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>
                        <div className="mb-3">
                          <Label className="form-label">Enter Password</Label>
                          <OutlinedInput
                            name="password"
                            placeholder="Enter Password"
                            type={showPassword['password'] ? 'text' : 'password'}
                            fullWidth
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ''}
                            error={!!(validation.touched.password && validation.errors.password)}
                            size='small'
                            endAdornment={
                              <InputAdornment position="center">
                                <IconButton
                                  aria-label={
                                    showPassword['password'] ? 'hide the password' : 'display the password'
                                  }
                                  onClick={() => handleClickShowPassword('password')}
                                  edge="end"
                                >
                                  {showPassword['password'] ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {validation.touched.password && validation.errors.password ? (
                            // <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            <FormHelperText error id="password-error">
                              {validation.errors.password}
                            </FormHelperText>

                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Enter Confirm Password</Label>
                          <OutlinedInput
                            type={showPassword['confirmPassword'] ? 'text' : 'password'}
                            fullWidth
                            name="confirmPassword"
                            placeholder="Enter Confirm Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmPassword}
                            error={!!(validation.touched.confirmPassword && validation.errors.confirmPassword)}
                            size='small'
                            endAdornment={
                              <InputAdornment position="center">
                                <IconButton
                                  aria-label={
                                    showPassword['confirmPassword'] ? 'hide the password' : 'display the password'
                                  }
                                  onClick={() => handleClickShowPassword('confirmPassword')}
                                  edge="end"
                                >
                                  {showPassword['confirmPassword'] ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                            // <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                            <FormHelperText error id="confirmPassword-error">
                              {validation.errors.confirmPassword}
                            </FormHelperText>
                          ) : null}
                        </div>

                        <div className="mt-3 text-end">
                          <button
                            className="btn btn-primary w-sm waves-effect waves-light"
                            type="submit"
                            disabled={!validation.isValid}
                          > Submit </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

      )
        : (<Pages404 />)}
    </div>
  );
}

export default VerifyPasswordReset;
