import React, { useEffect, useState } from 'react';
import {
  Row,
  Form,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from 'reactstrap';
import { PropTypes } from 'prop-types';
import { TextField, Autocomplete, Box } from '@mui/material';
import { DEFAULT_DATE_FORMAT, SCHEDULE_PROPERTY_VISIT_STATUS } from '../../common/constant';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function VisitRequestFilterDrawer({ isOpen, toggleDrawer, emitFilterItem, selectedFields, selectedInputs }) {
  const [isVisitRequestFilter] = useState(false);
  const [filterObject, setFilterObject] = useState({
    fullname: '',
    email: '',
    phone_no: '',
    property_name: '',
    visit_datetime: '',
    status: '',
    care_taker_name: '',
    care_taker_contact_no: '',
  });
  const filterInputs = [];
  const filterFields = [];

  const handleFilter = () => {
    if (filterObject.fullname) {
      filterFields.push('fullname');
      filterInputs.push(filterObject.fullname);
    }

    if (filterObject.email) {
      filterFields.push('email');
      filterInputs.push(filterObject.email);
    }

    if (filterObject.property_name) {
      filterFields.push('property_name');
      filterInputs.push(filterObject.property_name);
    }

    if (filterObject.visit_datetime) {
      filterFields.push('visit_datetime');
      filterInputs.push(filterObject.visit_datetime);
    }

    if (filterObject.status) {
      filterFields.push('status');
      filterInputs.push(filterObject.status);
    }

    if (filterObject.care_taker_name) {
      filterFields.push('care_taker_name');
      filterInputs.push(filterObject.care_taker_name);
    }

    if (filterObject.care_taker_contact_no) {
      filterFields.push('care_taker_contact_no');
      filterInputs.push(filterObject.care_taker_contact_no);
    }
    emitFilterItem(filterFields, filterInputs);
    toggleDrawer();
  };

  const resetFilter = () => {
    filterInputs.splice(0, filterInputs.length);
    filterFields.splice(0, filterFields.length);
    emitFilterItem(filterFields, filterInputs);
    toggleDrawer();
    setFilterObject({
      fullname: '',
      email: '',
      phone_no: '',
      property_name: '',
      visit_datetime: '',
      status: '',
      care_taker_name: '',
      care_taker_contact_no: '',
    });
  };

  useEffect(() => {
    if(selectedFields?.length)
    selectedFields.map((field, index) => {
      setFilterObject((prev)=> ({...prev, [field]:selectedInputs[index]}))
    });
  }, [])

  return (
    <Offcanvas
      isOpen={isOpen}
      direction='end'
      toggle={() => {
        toggleDrawer(!isVisitRequestFilter);
        setFilterObject({
          fullname: '',
          phone_no: '',
          status: '',
          email: '',
          visit_datetime: '',
          property_name: '',
          care_taker_name: '',
          care_taker_contact_no: '',
        });
      }}>
      <OffcanvasHeader toggle={toggleDrawer}>Visit Request Filter</OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className=''>
            <Row>
              {/* Full Name */}
              <div className='col-md-6 col-12 mb-3'>
                <TextField
                  className='form-control'
                  type='text'
                  label='Full Name'
                  name='fullname'
                  value={filterObject.fullname}
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      fullname: e.target.value,
                    });
                  }}
                  size='small'
                />
              </div>

              {/* Contact Number */}
              <div className='col-md-6 col-12 mb-3'>
                <TextField
                  className='form-control'
                  type='text'
                  label='Contact Number'
                  name='phone_number'
                  value={filterObject.phone_number}
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      phone_number: e.target.value,
                    });
                  }}
                  size='small'
                />
              </div>

              {/* Status */}
              <div className='col-md-6 col-12 mb-3'>
                <Autocomplete
                  value={filterObject.status}
                  onChange={(event, newValue) => {
                    setFilterObject({
                      ...filterObject,
                      status: newValue,
                    });
                  }}
                  renderOption={(props, option) => (
                    <Box component='li' key={option} {...props}>
                      {option}
                    </Box>
                  )}
                  options={Object.values(SCHEDULE_PROPERTY_VISIT_STATUS)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Select Visit Request Status'
                      size='small'
                    />
                  )}
                />
              </div>

              {/* Email */}
              <div className='col-md-6 col-12 mb-3'>
                <TextField
                  className='form-control'
                  type='text'
                  label='Email ID'
                  name='email'
                  value={filterObject.email}
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      email: e.target.value,
                    });
                  }}
                  size='small'
                />
              </div>
              
              {/* Property Name */}
              <div className='col-md-6 col-12 mb-3'>
                <TextField
                  className='form-control'
                  type='text'
                  label='Property Name'
                  name='property_name'
                  value={filterObject.property_name}
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      property_name: e.target.value,
                    });
                  }}
                  size='small'
                />
              </div>
              
              {/* Care Taker Name */}
              <div className='col-md-6 col-12 mb-3'>
                <TextField
                  className='form-control'
                  type='text'
                  label='Care Taker Name'
                  name='care_taker_name'
                  value={filterObject.care_taker_name}
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      care_taker_name: e.target.value,
                    });
                  }}
                  size='small'
                />
              </div>
              
              {/* Care Taker Phone No */}
              <div className='col-md-6 col-12 mb-3'>
                <TextField
                  className='form-control'
                  type='text'
                  label='Care Taker Contact No'
                  name='care_taker_contact_no'
                  value={filterObject.care_taker_contact_no}
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      care_taker_contact_no: e.target.value,
                    });
                  }}
                  size='small'
                />
              </div>

              {/* Visit Date Time*/}
              <div className="col-md-6 col-12 mb-3">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Select Visit Date"
                      format={DEFAULT_DATE_FORMAT}
                      slotProps={{ textField: { size: 'small' } }}
                      name='visit_datetime'
                      className='form-control'
                      value={filterObject.visit_datetime? dayjs(filterObject.visit_datetime): null}
                      onChange={(e) => {
                        setFilterObject({
                          ...filterObject,
                          visit_datetime: dayjs(e).toDate(),
                        });
                      }}
                      size='small'
                      referenceDate={dayjs(new Date())}
                    />
                </LocalizationProvider>
              <div className="form-group mb-0">
                {/* <Flatpickr
                  className="form-control d-block"
                  placeholder="Leaving date"
                  options={{
                    altInput: true,
                    altFormat: 'F j, Y',
                    dateFormat: 'Y-m-d',
                  }}
                /> */}
              </div>
            </div>

            </Row>
            <div className='formActions btn_right'>
              <Button className='yellow_gradient_btn me-2' onClick={handleFilter}>
                Apply Filter
              </Button>
              <Button className='red_gradient_btn' onClick={resetFilter}>
                Reset Filter
              </Button>
            </div>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
}

VisitRequestFilterDrawer.propTypes = {
  isOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  emitFilterItem: PropTypes.func,
}
export default VisitRequestFilterDrawer;
