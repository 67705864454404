import React, { useEffect, useState } from 'react';
import {
  Row,
  Form,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from 'reactstrap';
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { PropTypes } from 'prop-types';
import FileList from '../Common/FileList';
import { formatBytes, getS3BaseUrl } from '../../helpers/string_helper';
import uuid from 'react-native-uuid';
import { getTenantDetails } from '../../api/tenant';
import { toast } from 'react-toastify';
import { Avatar } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import dayjs from 'dayjs';
import ActivityTimeline from '../ActiveProperties/ActivePropertiesDetail/activityTimeline';
import { ALL_MODULES, DEFAULT_DATE_FORMAT } from '../../common/constant';
import { getLivingStatus } from '../../common/tenant';
import { fetchBedType } from '../../common/amenities_icon';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  const { detailValue } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>
            {React.cloneElement(children, { detailValue })}
          </Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TenantDetailDrawer({ tenantId, isOpen, setIsOpen, payout_percentage }) {
  const [tenantDetails, setTenantDetails] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(0);
  const [livingState, setLivingState] = useState({ livingInfo: null, livingStatus: null });
  const [bedData, setBedData] = useState()
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        ...(
          !tenantDetails?.user?.profile_photo ?
            { bgcolor: stringToColor(name) }
            : ''
        ),
        width: 'auto',
        height: 'inherit',
        'object-fit': 'cover',
        'font-size': '1.8rem',
      },
      children: `${name
        .split(' ')
        .map((f) => f[0])
        .join('')}`,
    };
  }
  const fetchTenantData = async () => {
    try {
      const response = await getTenantDetails(+tenantId);
      if (response?.status === 200) {
        setTenantDetails(response?.data?.data);
        setLivingState(getLivingStatus(response?.data?.data))
        setBedData(response?.data?.data?.user?.bed ? response?.data?.data?.user?.bed : response?.data?.data?.user?.advance_bed_booking)
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
    }
  };
  const s3BaseUrl = getS3BaseUrl()
  useEffect(() => {
    if (tenantId) {
      fetchTenantData();
    }
  }, [tenantId]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const showInfo = Boolean(anchorEl);
  const [anchorElPaid, setAnchorElPaid] = useState(null)

  const handleClickForPaid = (event) => {
    setAnchorElPaid(event.currentTarget);
  };
  const handleCloseForPaid = () => {
    setAnchorElPaid(null);
  };

  const showInfoForFuture = Boolean(anchorElPaid)

  // If multiple vacant request is avalable extract the latest same bed's vacant request
  if (tenantDetails?.vacant_requests) tenantDetails.current_vacant_request = tenantDetails?.vacant_requests.filter((vc) => vc?.bed_id === tenantDetails?.user?.bed?.id && tenantDetails?.id === vc?.tenant_id).slice(-1)[0];

  return (
    <>
      <Offcanvas
        isOpen={isOpen}
        direction='end'
        toggle={() => setIsOpen(!isOpen)}
      >
        <OffcanvasHeader toggle={() => setIsOpen(!isOpen)} />
        <OffcanvasBody>
          <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="aPTabs"
            >
              <Tab label="Basic Details" {...a11yProps(0)} />
              <Tab label="Activity Timeline" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Form>
              <div className='tenantDetailsDrawer'>
                <Row className='mb-3'>
                  <div className='col-sm-12'>
                    <div className='custom-image-upload'>
                      <div
                        className="image-placeholder"
                      // style={{ paddingBottom: "30px" }}
                      >
                        <Avatar
                          variant='square'
                          src={`${s3BaseUrl}${tenantDetails?.user?.profile_photo}`}
                          {...(tenantDetails?.user?.name &&
                            stringAvatar(tenantDetails?.user?.name))}
                        />
                      </div>
                      <div className='upload_picture mx-3'>
                        <div className='pro_person_name'>
                          {tenantDetails?.user?.name}
                        </div>
                        <div className='pro_person_mob'>
                          <i className='fas fa-phone-alt me-1' />
                          <span className='country_code'>+91</span>
                          <span className='pr_person_contact'>
                            {tenantDetails?.user?.contact_number ?? 'N/A'}
                          </span>
                        </div>
                        <div className='pro_person_mob tenantDetailEmail'>
                          <span className='pr_person_contact d-flex align-items-baseline'>
                            <i className='fas fa-envelope me-1' />
                            {tenantDetails?.user?.email ?? 'N/A'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
                <Row className='mb-3'>
                  <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                    <div className='tenantDetailTitle'>Tenant Type:</div>
                    <div className='tenantDetailDesc'>
                      {tenantDetails?.type ?? 'N/A'}
                    </div>
                  </div>
                  <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                    <div className='tenantDetailTitle'>Tenant Id :</div>
                    <div className='tenantDetailDesc'>
                      {tenantDetails?.user?.code ?? 'N/A'}
                    </div>
                  </div>
                </Row>

                <Row className='mb-3'>
                  <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                    <div className='tenantDetailTitle'>Tenant Status:</div>
                    <div className='tenantDetailDesc'>
                      {tenantDetails?.user?.status ?? 'N/A'}
                    </div>
                  </div>
                  <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                    <div className='tenantDetailTitle'>
                      Tenant’s Blood Group:
                    </div>
                    <div className='tenantDetailDesc'>
                      {tenantDetails?.user?.blood_group ?? 'N/A'}
                    </div>
                  </div>
                </Row>

                <Row className='mb-3'>
                  <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                    <div className='tenantDetailTitle'>
                      Emergency Contact Person:
                    </div>
                    <div className='tenantDetailDesc'>
                      {tenantDetails?.local_guardian_name ?? 'N/A'}
                    </div>
                  </div>

                  <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                    <div className='tenantDetailTitle'>
                      Emergency Contact Number:
                    </div>
                    <div className='tenantDetailDesc'>
                      +91 {tenantDetails?.local_guardian_contact_no ?? 'N/A'}
                    </div>
                  </div>
                </Row>

                <Row className='mb-3'>
                  <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                    <div className='tenantDetailTitle'>Agreement Status:</div>
                    <div className='tenantDetailDesc'>
                      {tenantDetails?.agreement?.status ?? 'N/A'}
                    </div>
                  </div>

                  <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                    <div className='tenantDetailTitle'>
                      Police Verification Status:
                    </div>
                    <div className='tenantDetailDesc'>
                      {tenantDetails?.police_verification?.status ?? 'N/A'}
                    </div>
                  </div>
                </Row>

                <Row className='mb-3'>
                  <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                    <div className='tenantDetailTitle'>
                      Reason for Payout Percentage:&nbsp;
                    </div>
                    <div className='tenantDetailDesc'>
                      {tenantDetails?.reason_payout_percentage ?? 'N/A'}
                    </div>
                  </div>
                  <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                    <div className='tenantDetailTitle'>
                      Payout Percentage:
                    </div>
                    <div className='tenantDetailDesc'>
                      {tenantDetails?.custom_payout_percentage > 0 ? tenantDetails?.custom_payout_percentage : payout_percentage}%
                    </div>
                  </div>
                  {!bedData && (
                    <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                      <div className='tenantDetailTitle'>
                        Property Name:
                      </div>
                      <div className='tenantDetailDesc'>
                        {tenantDetails?.property?.name}
                      </div>
                    </div>
                  )}
                </Row>

                <Row className='mb-3'>
                  {(tenantDetails?.vacant_requests?.length > 0 && tenantDetails?.vacant_requests[0]?.leaving_date || tenantDetails?.user?.bed) && (
                    <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                      <div className='tenantDetailTitle'>
                        Living Status:
                      </div>
                      <div className='tenantDetailDesc'>
                        {livingState.livingStatus ?? 'N/A'}
                      </div>
                    </div>
                  )}
                  {livingState.livingInfo &&
                    (<div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                      <div className='tenantDetailTitle'>
                        Living Info:
                      </div>
                      {livingState.livingInfo}
                    </div>)}
                </Row>

                <Row className='mb-3'>
                  {(tenantDetails?.applied_promo_code) && (
                    <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                      <div className='tenantDetailTitle'>
                        Promo Code:
                      </div>
                      <div className='tenantDetailDesc'>
                        {tenantDetails?.applied_promo_code ?? 'N/A'}
                      </div>
                    </div>
                  )}
                  {tenantDetails?.discount_value &&
                    (<div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                      <div className='tenantDetailTitle'>
                        Discount value:
                      </div>
                      {tenantDetails?.discount_value}
                    </div>)}
                </Row>

                {bedData && (tenantDetails?.current_vacant_request?.leaving_date ? dayjs().format(DEFAULT_DATE_FORMAT) <= dayjs(tenantDetails?.current_vacant_request?.leaving_date).format(DEFAULT_DATE_FORMAT) : true) ? (
                  <div className='property-data'>
                    <Row className='mb-3'>
                      <div className='col-sm-6 d-flex align-items-center custom_border_bottom pb-3'>
                        <div className='tenantDetailTitle'>Property Name:</div>
                        <div className='tenantDetailDesc'>
                          {bedData?.room?.flat?.floor?.property?.name ?? 'N/A'}
                        </div>
                      </div>

                      <div className='col-sm-6 d-flex align-items-center custom_border_bottom pb-3'>
                        <div className='tenantDetailTitle'>Date of Joining:</div>
                        <div className='tenantDetailDesc'>{tenantDetails?.joining_date ? dayjs(tenantDetails?.joining_date).format(DEFAULT_DATE_FORMAT) : "N/A"}</div>
                      </div>
                    </Row>

                    <Row className='mb-3'>
                      <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                        <div className='tenantDetailTitle'>Floor Number:</div>
                        <div className='tenantDetailDesc'>{bedData?.room?.flat?.floor?.name ?? 'N/A'}</div>
                      </div>

                      <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                        <div className='tenantDetailTitle'>Flat Number:</div>
                        <div className='tenantDetailDesc'>{bedData?.room?.flat?.name ?? 'N/A'}</div>
                      </div>

                    </Row>
                    <Row className='mb-3'>
                      <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                        <div className='tenantDetailTitle'>Room Number:</div>
                        <div className='tenantDetailDesc'>{bedData?.room?.name ?? 'N/A'}</div>
                      </div>

                      <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                        <div className='tenantDetailTitle'>Room Type:</div>
                        <div className='tenantDetailDesc'>{bedData?.room?.room_type?.name ?? 'N/A'}</div>
                      </div>
                    </Row>

                    <Row className='mb-3'>
                      <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                        <div className='tenantDetailTitle'>Bed Type:</div>
                        <div className='tenantDetailDesc'>{fetchBedType(bedData?.room?._count.beds) ?? 'N/A'}</div>
                      </div>

                      <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                        <div className='tenantDetailTitle'>Bed Number:</div>
                        <div className='tenantDetailDesc'>{bedData?.name ?? 'N/A'}
                          {tenantDetails?.user?.advance_bed_booking && (
                            <><InfoIcon onClick={handleClickForPaid} className='ms-2' />
                              <Popover
                                className='popup_block'
                                id={tenantDetails?.id}
                                open={showInfoForFuture}
                                anchorEl={anchorElPaid}
                                onClose={handleCloseForPaid}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                              >
                                <Typography sx={{ p: 2 }} className='popContent'>
                                  <p>Currently occupied by {bedData?.user?.name} , date of Leaving : {bedData?.user?.tenant?.vacant_requests[0]?.leaving_date ? dayjs(bedData?.user?.tenant?.vacant_requests[0]?.leaving_date).format(DEFAULT_DATE_FORMAT) : 'N/A'}</p>
                                </Typography>
                              </Popover>
                            </>
                          )}
                        </div>
                      </div>
                    </Row>

                    <Row className='mb-3'>
                      <div className='col-sm-6 d-flex align-items-center custom_border_bottom pb-3'>
                        <div className='tenantDetailTitle'>Bed Rate:</div>
                        <div className='tenantDetailDesc'>{bedData?.price ?? 'N/A'}</div>
                      </div>
                      <div className='col-sm-6 d-flex align-items-center custom_border_bottom pb-3'>
                        <div className='tenantDetailTitle'>Caution Money:</div>
                        <div className='tenantDetailDesc'>{tenantDetails?.refund_deposits[0]?.deposit_amount ?? 'N/A'}
                          {tenantDetails?.refund_deposits[0]?.payment && (
                            <>
                              <InfoIcon onClick={handleClick} className='ms-2' />
                              <Popover
                                className='popup_block'
                                id={tenantDetails.id}
                                open={showInfo}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                              >
                                <Typography sx={{ p: 2 }} className='popContent'>
                                  <p>Payment Date: {tenantDetails?.refund_deposits[0]?.payment ? dayjs(tenantDetails?.refund_deposits[0]?.payment?.payment_date).format('DD-MM-YYYY') : 'N/A'}</p>
                                  <p>Transaction ID: {tenantDetails?.refund_deposits[0]?.payment ? tenantDetails?.refund_deposits[0]?.payment?.transaction_id : 'N/A'}</p>
                                </Typography>
                              </Popover>
                            </>
                          )}
                        </div>
                      </div>
                    </Row>

                    <Row className='mb-3'>
                      <div className='col-sm-12 d-flex align-items-center custom_border_bottom pb-3'>
                        <div className='tenantDetailTitle tenantDetHalfFullTitle ms-0'>
                          Property Address:
                        </div>
                        <div className='tenantDetailDesc'>
                          {bedData?.room?.flat?.floor?.property?.address?.address_line_1 ?? 'N/A'}
                        </div>
                      </div>
                    </Row>
                  </div>) : null}
                <Row className='mb-3'>
                  <div className='col-sm-12 custom_border_bottom pb-3'>
                    {
                      tenantDetails?.other_documents?.length > 0 && (
                        <>
                          <div className='tenantDetailTitle tenantDetFullTitle'>
                            Attachments:
                          </div>
                          <FileList files={tenantDetails?.other_documents.map((doc) => ({ ...doc, uniqueId: uuid.v4(), formattedSize: formatBytes(doc.size) }))} />
                        </>
                      )
                    }
                  </div>
                </Row>
              </div>
            </Form>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <>
              <ActivityTimeline
                module={ALL_MODULES.TENANTS}
                module_id={tenantId}
                activityCompHeight={70}
              />
            </>
          </CustomTabPanel>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

TenantDetailDrawer.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  tenantId: PropTypes.number,
}
export default TenantDetailDrawer;
