import { globalSettingsViewById, updateFromList, getAllGlobalSettings, updateCMS } from '../globalSettings';
import { toast } from "react-toastify";

const listApi = async () => {
  try {
    const response = await getAllGlobalSettings()
    return response.data.data
  } catch (error) {
    return Promise.reject(error)
  }
};
const viewByIdApi = async (id) => {
  try {
    const response = await globalSettingsViewById(id)
    return response.data.data
  } catch (error) {
    return Promise.reject(error)
  }
};
const editApi = async (values) => {
  try {
    const response = await updateFromList(values)
    if (response.status === 200) {
      toast.success(response.data.message);
      return response.data.data.token
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error.message);  }
};

const editCMS = async (values) => {
  try {
    const response = await updateCMS(values)
    if (response.status === 200) {
      toast.success(response.data.message);
      return true;
    } else {
      toast.error(response.data.message);
      return false
    }
  } catch (e) {
    if (e?.response?.data?.message) toast.error(e?.response?.data?.message);
    else toast.error(e.message);
    console.log(e);    
    return false
  }
};

export {
  listApi,
  editApi,
  editCMS,
  viewByIdApi

}
