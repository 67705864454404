import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { FormControl } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultipleSelectSearchCheckbox({
  label,
  placeholder,
  options = [],
  selectedIds = [],
  emitItem,
  isDisable,
}) {
  const [value, setValue] = React.useState(options?.filter((item) => selectedIds.includes(item?.id)));

  return (
    <FormControl sx={{ mb: 1, width: '100%' }}>
      <Autocomplete
        size='small'
        // fullWidth
        multiple
        value={value}
        filterOptions={(options, params) => {
          const filter = createFilterOptions()
          const filtered = filter(options, params)
          return [{ name: 'Select All', all: true }, ...filtered]
        }}
        onChange={(event, newValue) => {
          console.log({ newValue });
          if (newValue.find(option => option.all)) {
            setValue(value.length === options.length ? [] : options)
            emitItem(options);
          } else {
            setValue(newValue);
            emitItem(newValue);
          }
        }}
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={option.all ? !!(value.length === options.length) : selected}
            />
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} fullWidth={true} label={label} disabled={isDisable} placeholder={placeholder} />
        )}
      />
    </FormControl>
  );
}
