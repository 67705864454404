import React from 'react';
import Select from 'react-select';
import { VACANT_REQUEST_ACKNOWLEDGE_STATUS } from '../../common/constant';
import { createTheme } from '@mui/material';

const colors = createTheme()?.palette;
const options = [
  { value: VACANT_REQUEST_ACKNOWLEDGE_STATUS.ACKNOWLEDGED, label: VACANT_REQUEST_ACKNOWLEDGE_STATUS.ACKNOWLEDGED },
];

const customStyles = {
  control: (provided, state) => {
    let backgroundColor = 'transparent';

    if (state.selectProps.value) {
      const selectedOption = state.selectProps.options.find(
        (option) => option.value === state.selectProps.value.value,
      );

      backgroundColor = selectedOption?.value ===
        VACANT_REQUEST_ACKNOWLEDGE_STATUS.ACKNOWLEDGED
        ? colors.success.main
        // : selectedOption?.value === VACANT_REQUEST_ACKNOWLEDGE_STATUS.PENDING
        //   ? colors.warning.main
        //   : selectedOption?.value === VACANT_REQUEST_ACKNOWLEDGE_STATUS.REFUNDED
        //     ? colors.primary.main
        : selectedOption?.value === VACANT_REQUEST_ACKNOWLEDGE_STATUS.CANCELLED
          ? colors.error.main : colors.warning.main;
    }

    return {
      ...provided,
      backgroundColor,
    };
  },
  singleValue: (provided, state) => ({
    ...provided,
    color: state.data.value ===
      // VACANT_REQUEST_ACKNOWLEDGE_STATUS.PENDING ? colors.warning.contrastText :
      //   state.data.value === VACANT_REQUEST_ACKNOWLEDGE_STATUS.REFUNDED ? colors.primary.contrastText :
      state.data.value === VACANT_REQUEST_ACKNOWLEDGE_STATUS.ACKNOWLEDGED ? colors.success.contrastText :
      colors.warning.contrastText, // Default color,
  }),
};

const SelectBox = ({ selectedOption, handleChange, isDisabled }) => {
  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={options}
      isDisabled={[VACANT_REQUEST_ACKNOWLEDGE_STATUS.REFUNDED].includes(selectedOption.value) || isDisabled}
      className="customSelectfilter"
      styles={customStyles}
    />
  );
}

export default SelectBox;
