import React from "react";
import moment from "moment";
import { DEFAULT_DATETIME_FORMAT, TENANT_LIVING_STATUS, VACANT_REQUEST_ACKNOWLEDGE_STATUS } from "./constant";

export const getLivingStatus = (tenant) => {

  if (tenant?.vacant_requests?.length > 0) {
    tenant.vacant_requests = tenant?.vacant_requests.filter((vc) => vc?.acknowledge_status !== VACANT_REQUEST_ACKNOWLEDGE_STATUS.CANCELLED);
    tenant.current_vacant_request = tenant?.vacant_requests.filter((vc) => vc?.bed_id === tenant?.user?.bed?.id).slice(-1)[0];
    tenant.last_vacant_request = tenant?.vacant_requests.slice(-1)[0];
  }
  let livingInfo, livingStatus;
  if (tenant?.user?.bed) {
    if (tenant?.joining_date && moment().isBefore(moment(tenant?.joining_date), 'day')) {
      livingStatus = TENANT_LIVING_STATUS.UPCOMING_JOINER;
      livingInfo = `Will be joining from ${moment(tenant?.joining_date).format("MMMM Do YYYY")}`;
    } else {
      livingStatus = TENANT_LIVING_STATUS.CURRENTLY_LIVING;
      if (tenant?.current_vacant_request?.leaving_date && !moment().isAfter(moment(tenant?.current_vacant_request?.leaving_date), 'day'))
        livingInfo = `Leaving on ${moment(tenant?.current_vacant_request?.leaving_date).format("MMMM Do YYYY")}`
    }
  } else if (tenant?.joining_date && moment().isBefore(moment(tenant?.joining_date), 'day')) {
    livingStatus = TENANT_LIVING_STATUS.UPCOMING_JOINER;
    livingInfo = `Will be joining from ${moment(tenant?.joining_date).format("MMMM Do YYYY")}`;
  } else if (tenant?.cancel_requests?.length > 0) {
    livingStatus = TENANT_LIVING_STATUS.CANCELLED_BOOKING;
    livingInfo = <span style={{ whiteSpace: 'pre-line' }}>{`Cancelled booking on ${moment(tenant?.cancel_requests[0].cancelled_date).format(DEFAULT_DATETIME_FORMAT)}.
    Reason: ${tenant?.cancel_requests[0].note?.description}.`}</span>;
  } else if (tenant?.vacant_requests?.length > 0 && moment().isAfter(moment(tenant?.last_vacant_request?.leaving_date))) {
    livingStatus = TENANT_LIVING_STATUS.PAST_TENANT;
    livingInfo = `Left on ${moment(tenant?.last_vacant_request?.leaving_date).format("MMMM Do YYYY")}`;
  } else if (tenant?.vacant_requests?.length === 0) {
    livingStatus = TENANT_LIVING_STATUS.PROPERTY_UNASSIGNED;
  }
  return { livingStatus, livingInfo };
};