import React, { useState } from 'react';
import {
  TabContent, TabPane, NavLink, NavItem, Nav,
} from 'reactstrap';
import classnames from 'classnames';
import ActiveTab from './ActiveTab';
import ExpiredTab from './ExpiredTab';

export const tabs = {
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
}
export default function VacantRequestTabs({ setSelectedTab, fetchCounterData, navigateState }) {
  const [activeTab, setActiveTab] = useState(0);

  function toggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  useState(() => {
    if (navigateState?.role === tabs.ACTIVE) {
      toggle(0);
      handleTabClick(tabs.ACTIVE)
    }
    else if (navigateState?.role === tabs.EXPIRED) {
      toggle(1);
      handleTabClick(tabs.EXPIRED)
    }
  }, [])
  return (
    <div className="complainTicketTabs mt-4">
      <Nav pills>
        <NavItem>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={classnames({
              active: activeTab === 0,
            })}
            onClick={() => {
              toggle(0);
              handleTabClick(tabs.ACTIVE)
            }}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-home" />
            </span>
            <span className="d-none d-sm-block mx-2">Active Vacant Requests</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={classnames({
              active: activeTab === 1,
            })}
            onClick={() => {
              toggle(1);
              handleTabClick(tabs.EXPIRED)
            }}
          >
            <span className="d-block d-sm-none">
              <i className="far fa-user" />
            </span>
            <span className="d-none d-sm-block mx-2">Past Vacant Requests</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} className="p-3 text-muted">
        <TabPane tabId={0}>
          <ActiveTab fetchCounterData={fetchCounterData} activeTab={activeTab} />
        </TabPane>
        <TabPane tabId={1}>
          <ExpiredTab fetchCounterData={fetchCounterData} activeTab={activeTab} />
        </TabPane>
      </TabContent>
    </div>
  );
}
