/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, Modal } from 'reactstrap';
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table';
// import moment from 'moment';
import PaginationComponent from '../Common/PaginationComponent';
import {
  ACTIONS,
  ALL_MODULES,
  BILL_PAID_STATUS,
  DEFAULT_DATE_FORMAT,
  DEFAULT_PER_PAGE_VALUE,
  OPERATION_TYPE,
  PAYMENT_TYPES,
} from '../../common/constant';
import Search from '../Common/GlobalSearch';
import TableHeaders from '../Common/TableHeaders';
import DescriptionIcon from '@mui/icons-material/Description';
// import refundIcon from "../../assets/images/icons/refund.svg";
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton } from '@mui/base/MenuButton';
import { MenuItem } from '@mui/base/MenuItem';
import { styled } from '@mui/system';
import { grey } from '@mui/material/colors';
import { connect } from 'react-redux';
import NodataFound from '../Common/NoDataFound';
import CircularProgress from '@mui/material/CircularProgress';
import NoDataIcon from "../../assets/images/no-data.png";
import { toast } from 'react-toastify';
import { fetchBedType } from '../../common/amenities_icon';
import PGVacantRequestFilterDrawer from './PGVacantRequestFilterDrawer';
import { fetchPgVacantCardData, getAllPgVacantRequest, removeExpiredPgVacantRequest } from '../../api/pg-vacant-request';
import dayjs from 'dayjs';
import SelectBox from './selectBox';
import ConfirmationModal from '../Common/ConfirmationModal';
import { PGVacantRequestDetailsViewer } from './PGVacantRequestDetailDrawer';
import { useLocation } from 'react-router-dom';
import { usePermissionGiven } from '../Hooks/UserPermission';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography'

function ExpiredTab(props) {
  const [isPGVacantRequestFilter, setPGVacantRequestFilter] = useState(false);
  const [isPGVacantRequestDetails, setPGVacantRequestDetails] = useState(false);
  const [vacantRequestList, setVacantRequestList] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState({})
  const [searchItem, setSearchItem] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE_VALUE);
  const [noteModalActionAndPayload, setNoteModalActionAndPayload] = useState({
    action: null,
    item: null
  })
  const [noteModal, setNoteModal] = useState(false);
  const [page, setPage] = useState(1)
  const [update, setUpdate] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [filterFields, setFilterFields] = useState(['expired'])
  const [filterInputs, setFilterInputs] = useState([1])
  const [sort, setSort] = useState();
  const [reqId, setReqId] = useState('');
  const [selectedRow, setSelectedRow] = useState();
  const [isDeleteConfirm, setDeleteConfirm] = useState({
    status: false,
    id: null,
  });
  const hasDeletePermission = usePermissionGiven(ALL_MODULES.PG_VACANT_REQUESTS, OPERATION_TYPE.DELETE)
  const hasEditPermission = usePermissionGiven(ALL_MODULES.FINANCE, OPERATION_TYPE.DEPOSIT_PAYMENT)
  const [redirectedId] = useState(useLocation()?.state?.id);

   const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseForInfo = () => {
    setAnchorEl(null);
  };
  const showInfo = Boolean(anchorEl);


  // Request filter drawer
  const togglePGVacantRequestFilter = () => {
    setPGVacantRequestFilter(!isPGVacantRequestFilter);
  };

  // Request details drawer
  const togglePGVacantRequestDetails = () => {
    setPGVacantRequestDetails(!isPGVacantRequestDetails);
  };
  const Listbox = styled('ul')(
    ({ theme }) => `
      font-size: 16px;
      padding: 10px;
      margin: 12px 0;
      min-width: 200px;
      border-radius: 5px;
      outline: 0px;
      background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      box-shadow: 0px 4px 6px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
      };
      z-index: 99;
      position: absolute;
      top: 100%;
      right: 0;
      list-style-type: none;
    `,
  );

  const ListboxItem = styled('li')(
    `padding: '10px'`
  )

  const StyledMenuItem = styled(MenuItem)`
  && {
    padding: 10px;
    /* Add more styles as needed */
  }
`;

  const fetchAllVacantRequestList = async (searchData = "", paginatedData = { page, limit: rowsPerPage }) => {
    const params = {
      ...paginatedData,
      ...((searchData?.search || searchItem?.search) && {
        search: searchItem?.search ? searchItem.search : searchData?.search
      }),
      ...(filterFields && filterInputs && {
        filter_fields: filterFields,
        filter_inputs: filterInputs
      }),
      ...(sort?.order && sort?.direction && {
        ...sort
      })
    }
    const resultData = await vacantRequestApi(params)
    setVacantRequestList(resultData)

    if (resultData.length > 0 && redirectedId && resultData.some((vr) => vr.id === redirectedId)) {
      const vr = resultData.find((vr) => vr.id === redirectedId);
      setSelectedRow(vr);
      setReqId(vr?.id);
      togglePGVacantRequestDetails();
    }
  }

  const vacantRequestApi = async (params) => {
    try {
      setIsLoading(true)
      const response = (await getAllPgVacantRequest(params)).data.data
      setPaginationMeta(response.meta)
      let level = (response.meta.current_page - 1) * response.meta.per_page;
      const resultData = response.data.map((res) => {
        return {
          id: res.id,
          indexNo: ++level,
          tenant_name: res.tenant?.user?.name,
          property_name: res.tenant?.property?.name ?? '',
          created_by: res.creator?.name,
          bed_type: fetchBedType(res?.bed?.room?._count.beds),
          acknowledge_status: res.acknowledge_status,
          room_type: res?.bed?.room?.room_type?.name,
          monthly_rent: res.bed.price,
          joining_date: res.tenant?.joining_date ? dayjs(res.tenant?.joining_date).format('DD-MM-YYYY') : null,
          leaving_date: res?.leaving_date ? dayjs(res.leaving_date).format('DD-MM-YYYY') : null,
          note: res?.note,
          leave_reason: res?.leave_reason,
          bank_detail: res?.tenant?.bank_detail,
          refund_deposit: res?.tenant?.refund_deposits?.filter((refund) => refund?.type === PAYMENT_TYPES.DEPOSIT_RECEIVE)?.[0],
          refund_amount: res.refund_amount,
          deposit_refund_history: res?.tenant?.refund_deposits?.filter((refund) => refund?.type === PAYMENT_TYPES.DEPOSIT_REFUND)?.[0],
          tenant_rent_slip: res.tenant?.tenant_rent_slip[0],
          payment_history: res.tenant?.payment_history[0]
        };
      });
      return resultData
    } catch (error) {
      return toast.error(error?.response?.data?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const paginationModelMethod = async (data) => {
    let params = {
      ...(searchItem?.search && {
        ...searchItem
      })
    }
    if (data?.page) {
      setPage(data.page)
      params.page = data.page
      fetchAllVacantRequestList(params, { page: data.page, limit: rowsPerPage })
    } else {
      setUpdate(prev => prev + 1)
      setPage(1)
      setRowsPerPage(data?.limit)
      params.limit = data.limit
      fetchAllVacantRequestList(params, { page: data.page, limit: data?.limit })
    }
  };

  const globalSearch = async (params) => {
    setVacantRequestList([]);
    setSearchItem(params)
    setPage(1)
    setPaginationMeta()
    setUpdate((pre) => pre + 1)
    const resultData = await vacantRequestApi({
      ...params,
      limit: params.limit,
      page: params.page,
      ...(params.search && {
        search: params.search,
      }),
      ...(sort?.order && { order: sort.order }),
      ...(sort?.direction && { direction: sort.direction }),
    })
    setVacantRequestList(resultData)
  };

  const handleNoteModalToggle = (action, item, modalState) => {
    setNoteModalActionAndPayload({
      action,
      item,
      module: ALL_MODULES.PG_VACANT_REQUESTS
    })
    setNoteModal(prev => {
      if (modalState) {
        return modalState
      }
      return !prev
    })
  }

  const handleVacantRequestFilter = async (filterFields, filterInputs) => {
    setVacantRequestList([]);
    setPage(1)
    setPaginationMeta()
    setUpdate((pre) => pre + 1)
    setFilterInputs(filterInputs)
    setFilterFields(filterFields)
    const resultData = await vacantRequestApi({
      limit: rowsPerPage,
      ...(searchItem && {
        ...searchItem,
      }),
      filter_fields: filterFields,
      filter_inputs: filterInputs,
      ...(sort?.order && { order: sort.order }),
      ...(sort?.direction && { direction: sort.direction }),
    });
    setVacantRequestList(resultData)
  }

  const removeExpiredVacantRequestHandler = async (id) => {
    try {
      const response = await removeExpiredPgVacantRequest(id);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        return true;
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      if (error.response?.data?.message) toast.error(error.response?.data?.message);
      else toast.error(error.message);
      console.log(error);
    }
    return false;
  };

  const handleSort = async (sort) => {
    setVacantRequestList([]);
    setSort(sort);
    const resultData = await vacantRequestApi({
      ...(sort?.order &&
        sort?.direction && {
        ...sort,
      }),
      ...(page && page),
      ...(rowsPerPage && { limit: rowsPerPage }),
      ...(searchItem && {
        ...searchItem,
      }),
      ...(filterFields &&
        filterInputs && {
        filter_fields: filterFields,
        filter_inputs: filterInputs,
      }),
    });
    setVacantRequestList(resultData);
  };

  // const handleStatusChange = async (id, status, notes = '', refund_amount = 0) => {
  //   try {
  //     const payload = {
  //       acknowledge_status: status,
  //       ...(notes && {
  //         note: notes?.note ? notes.note : notes
  //       }),
  //       ...(status === VACANT_REQUEST_ACKNOWLEDGE_STATUS.REFUNDED && {
  //         refund_amount: refund_amount,
  //       })
  //     }
  //     const response = (await updatePgVacantRequest(id, payload))?.data?.data
  //     if (payload?.acknowledge_status === VACANT_REQUEST_ACKNOWLEDGE_STATUS.CANCELLED && response?.tenant?.property?.status === PROPERTY_STATUS.LIVE) {
  //       socket.emit(SOCKET_EVENT.PG_VACANT_CANCEL_SEND, { ...response, property_id: response?.tenant?.property_id, status: response?.tenant?.property?.status })
  //     }

  //     setVacantRequestList((prev) => {
  //       const ind = prev.findIndex((v) => v.id === id)
  //       if (ind !== -1) {
  //         prev[ind].acknowledge_status = status
  //         prev[ind].note = notes ? { description: notes?.note ? notes.note : notes } : prev[ind].note,
  //           prev[ind].refund_amount = status === VACANT_REQUEST_ACKNOWLEDGE_STATUS.REFUNDED ? refund_amount : prev[ind].refund_amount
  //       }
  //       return [...prev]
  //     })
  //     await props.fetchCounterData()
  //     // handleCloseRefundModal()
  //     toast.success('Status updated successfully.')
  //     isPGVacantRequestDetails && setPGVacantRequestDetails(false)
  //   } catch (e) {
  //     toast.error(e?.response?.data?.message)
  //   } finally {
  //     setNoteModal(false)
  //   }
  // }

  const columns = [
    { column_name: `Tenant Name`, key_name: 'tenant_name', sort_enabled: true, style: { minWidth: '150px' } },
    { column_name: `Property Name`, key_name: 'property_name', sort_enabled: true, style: { minWidth: '145px' } },
    { column_name: `Leaving Date`, key_name: 'leaving_date', sort_enabled: true, style: { minWidth: '125px' } },
    { column_name: `Bed Type`, key_name: 'bed_type', sort_enabled: false, style: { minWidth: '170px' } },
    { column_name: `Room Type`, key_name: 'room_type', sort_enabled: true, style: { minWidth: '170px' } },
    { column_name: `Monthly Rent`, key_name: 'monthly_rent', sort_enabled: false, style: { minWidth: '150px' } },
    { column_name: `Joining Date`, key_name: 'joining_date', sort_enabled: false, style: { minWidth: '150px' } },
    { column_name: `Created By`, key_name: 'created_by', sort_enabled: false, style: { minWidth: '170px' } },
    { column_name: 'Leaving Reason', key_name: 'reason', sort_enabled: false, style: { minWidth: '125px' } },
    { column_name: ' Admin Acknowledgement Status', key_name: 'acknowledge_status', sort_enabled: false, style: { minWidth: '125px' } },
    { column_name: `Refund Amount`, key_name: 'refund_amount', sort_enabled: false, style: { minWidth: '170px' } },
    { column_name: `Admin's Comment`, key_name: 'admin_comment', sort_enabled: false, style: { minWidth: '170px' } },
  ]

  useEffect(() => {
    fetchAllVacantRequestList(searchItem, { page: 1, limit: rowsPerPage })
  }, [props.activeTab])

  return (
    <div className='tenantTab'>
      <Row>
        <Col className="col-12">
          <div className="flexbox_holder">
            <div className='app-search'>
              <Search
                listCall={globalSearch}
                params={{
                  limit: rowsPerPage,
                  page: 1,
                  order: sort?.order,
                  direction: sort?.direction,
                  filter_fields: filterFields,
                  filter_inputs: filterInputs,
                }}
              />
            </div>

            <div>
              <Button className="yellow_gradient_btn me-2" onClick={togglePGVacantRequestFilter}>
                <i className="mdi mdi-filter-variant" />
                Filter
              </Button>
            </div>
          </div>
          <Card className="table_card_holder">
            <CardBody>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >

                  {vacantRequestList.length === 0 && isLoading && (
                    <div style={{ textAlign: 'center' }}>
                      <CircularProgress />
                    </div>
                  )}
                  {vacantRequestList.length === 0 && !isLoading && (
                    <div style={{ textAlign: 'center' }}>
                      <NodataFound text="No pg vacant request to show" icon={NoDataIcon} />
                    </div>
                  )}
                  {vacantRequestList?.length > 0 && (
                    <Table
                      id="tech-companies-1"
                      className="table table-bordered"
                    >
                      <TableHeaders
                        headers={columns}
                        sortBy={sort ?? ''}
                        emitSortedColumn={handleSort}
                        module={ALL_MODULES.PG_VACANT_REQUESTS}
                      />
                      <Tbody>
                        {vacantRequestList?.length > 0 && vacantRequestList?.map((item, index) => (
                          <Tr key={index}>
                            <Td>{item.indexNo}</Td>
                            <Td className="cursor_pointer" onClick={() => {
                              setSelectedRow(item);
                              setReqId(item?.id);
                              togglePGVacantRequestDetails();
                            }}>{item.tenant_name}</Td>
                            <Td>{item.property_name}</Td>
                            <Td>{item.leaving_date}</Td>
                            <Td>{item.bed_type}</Td>
                            <Td>{item.room_type}</Td>
                            <Td>{item.monthly_rent}</Td>
                            <Td>{item.joining_date}</Td>
                            <Td>{item.created_by}</Td>
                            <Td className="d-flex justify-content-center">{item?.leave_reason && (
                              <DescriptionIcon
                                titleAccess='Show Leaving Reason'
                                style={{ textAlign: 'center', fontSize: '28px', cursor: 'pointer' }}
                                fontSize={'inherit'}
                                color='info'
                                onClick={() => handleNoteModalToggle(ACTIONS.VIEW, { description: item.leave_reason })}
                              />
                            )}</Td>
                            <Td><SelectBox
                              selectedOption={{ label: item.acknowledge_status, value: item.acknowledge_status }}
                              isDisabled={true}
                              /></Td>
                            <Td>
                              <span>{item.refund_amount}</span>
                              {item?.deposit_refund_history?.payment && (
                                <>
                                  <InfoIcon onClick={handleClick} className='ms-2' /><Popover
                                    className='popup_block'
                                    id={item?.id}
                                    open={showInfo}
                                    anchorEl={anchorEl}
                                    onClose={handleCloseForInfo}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }} className='popContent'>
                                      <p>Refund date: {item.deposit_refund_history?.payment ? dayjs(item.deposit_refund_history?.payment?.payment_date).format(DEFAULT_DATE_FORMAT) : 'N/A'}</p>
                                      <p>{item?.deposit_refund_history?.payment?.status === BILL_PAID_STATUS.PAID ? 'UTR No.:' : 'Transfer Id:'} {item.deposit_refund_history?.payment ? item.deposit_refund_history?.payment?.transaction_id : 'N/A'}</p>
                                    </Typography>
                                  </Popover>
                                </>
                              )}
                            </Td>
                            <Td>{item?.note && (
                              <DescriptionIcon
                                titleAccess='Show Admin Comment'
                                style={{ textAlign: 'center', fontSize: '28px', cursor: 'pointer' }}
                                fontSize={'inherit'}
                                color='info'
                                onClick={() => {
                                  handleNoteModalToggle(ACTIONS.VIEW, { description: item.note.description })
                                }}
                              />
                            )}</Td>
                            {(hasDeletePermission || (hasEditPermission && dayjs().isSameOrAfter(dayjs(item.leaving_date)))) && (
                              <Td className="threeDotsSpace">
                                <Dropdown>
                                  <MenuButton><i className='mdi mdi-dots-vertical font-size-15'></i></MenuButton>
                                  <Menu slots={{ listbox: Listbox }}>
                                    {hasDeletePermission && (
                                      <StyledMenuItem className="cursor-pointer" slots={{ ListboxItem: ListboxItem }} onClick={() =>
                                        setDeleteConfirm({ status: true, id: item?.id })
                                      }>
                                        <i className="mdi mdi-trash-can-outline font-size-18 align-middle me-1 text-muted" />
                                        Remove
                                      </StyledMenuItem>
                                    )}
                                  </Menu>
                                </Dropdown>
                              </Td>
                            )}
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  )}
                </div>

              </div>

              {vacantRequestList.length > 0 && (<div className="numbers_pagination_holder">
                <PaginationComponent
                  paginationMeta={paginationMeta}
                  handleChangePagination={paginationModelMethod}
                  update={update}
                >
                </PaginationComponent>
              </div>)}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {noteModal && noteModalActionAndPayload.action === ACTIONS.VIEW && (
        <Modal
          isOpen={noteModal}
          toggle={() => {
            setNoteModal(false);
          }}
          backdrop="static"
          centered
          fullscreen="md"
          size="lg"
          scrollable
        >
          <div className="modal-header">
            <div className="d-flex justify-content-center">
              <h5 className="modal-title mt-0 me-2" id="myModalLabel">
                Notes
              </h5>
            </div>
            <button
              type="button"
              onClick={() => {
                setNoteModal(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p
              dangerouslySetInnerHTML={{
                __html: noteModalActionAndPayload?.item?.description,
              }}
            ></p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                setNoteModal(false);
              }}
              className="btn btn-primary waves-effect waves-light"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
      )}

      {/* {noteModal && noteModalActionAndPayload.action === ACTIONS.CREATE && (
        <AddOrEditOrViewNoteModal
          handleNoteModalToggle={handleNoteModalToggle}
          handleSubmit={(notes) =>
            handleStatusChange(
              selectedId,
              VACANT_REQUEST_ACKNOWLEDGE_STATUS.CANCELLED,
              notes
            )
          }
          noteModalActionAndPayload={noteModalActionAndPayload}
        />
      )} */}

      {/* {isRefundModal && (
        <InitiateRefundAmount
          isOpen={isRefundModal}
          depositDetails={refundDepositDetails}
          handleClose={handleCloseRefundModal}
          handleSubmit={(data) =>
            handleStatusChange(
              selectedId,
              VACANT_REQUEST_ACKNOWLEDGE_STATUS.REFUNDED,
              data.note,
              data.refund_amount
            )
          }
        />
      )} */}

      {isPGVacantRequestFilter && (
        <PGVacantRequestFilterDrawer
          isOpen={isPGVacantRequestFilter}
          toggleDrawer={togglePGVacantRequestFilter}
          emitFilterItem={handleVacantRequestFilter}
          selectedFields={filterFields}
          selectedInputs={filterInputs}
        />
      )}

      {isDeleteConfirm.status &&
        <ConfirmationModal
          action={ACTIONS.DELETE}
          show={isDeleteConfirm.status}
          onCloseClick={() => setDeleteConfirm({ status: false, id: null })}
          onAcceptClick={async () => {
            const isDeleted = await removeExpiredVacantRequestHandler(isDeleteConfirm.id);
            if (isDeleted) {
              fetchAllVacantRequestList();
              fetchPgVacantCardData()
            }
            setDeleteConfirm({ status: false, id: null });
          }}
          isDisabled={isLoading}
        />}

      {isPGVacantRequestDetails && (
        <PGVacantRequestDetailsViewer
          isOpen={isPGVacantRequestDetails}
          toggleDrawer={togglePGVacantRequestDetails}
          selectedRow={selectedRow}
          reqId={reqId}
          // handleStatusChange={handleStatusChange}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.AuthLogin.auth.user,
});

export default connect(mapStateToProps, null)(ExpiredTab);
