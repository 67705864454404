import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { fetchAllActivityForDashboard } from "../../api/activity";
import { ACTIONS, ALL_MODULES, DEFAULT_DATETIME_FORMAT, TIME_INTERVAL } from "../../common/constant";
import { getInitials } from "../../constants/kanban_helper";
import { removeSpecial } from "../../helpers/string_helper";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import { Button, Container, Spinner } from "reactstrap";
import { DateRange } from "react-date-range";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import dayjs from "dayjs";
import NodataFound from "../../components/Common/NoDataFound";
import NoDataIcon from "../../assets/images/no-data.png";

function Activities() {
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [timeLineData, setTimeLineData] = useState([]);
  const [meta, setMeta] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectionRange, setSelectionRange] = useState([{
    startDate: new Date(),
    endDate: null,
    key: 'selection'
  }]);
  const [filterDate, setFilterDate] = useState(TIME_INTERVAL.THIS_MONTH)
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const [label, setLabel] = useState(["This Month"]);
  const [isShowDatePicker, setIsShowDatePicker] = useState(false);
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 160,
      },
    },
  };

  const handleChange = (event) => {
    let {
      target: { value },
    } = event;
    if (value === TIME_INTERVAL.CUSTOM) {
      setLabel(
        typeof value === 'string' ? value.split(',') : value,
      );
      setIsShowDatePicker(true);
      return;
    } else if (typeof value === 'object') {
      value = JSON.stringify(value)
    } else
      setLabel(
        typeof value === 'string' ? value.split(',') : value,
      );

    if (value !== filterDate) setTimeLineData([])
    setFilterDate(value)
    setPage(1);
  };

  const handleFilter = () => {
    if (selectionRange[0].startDate && selectionRange[0].endDate)
      handleChange({ target: { value: selectionRange[0] } });
    setIsShowDatePicker(false);
  }

  const getAllActivityData = async (pageLimit, currnetPage) => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      let params = {
        page: currnetPage,
        limit: pageLimit,
        date_range: filterDate,
      };

      const res = (await fetchAllActivityForDashboard(params)).data.data;
      setTimeLineData((prevState) => {
        return [...prevState, ...res.data];
      });
      setMeta(res?.meta);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllActivityData(limit, page);
  }, [filterDate]);

  const scrollHandler = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    if (scrollHeight - (scrollTop + clientHeight) < 2) {
      if (meta?.current_page < meta?.last_page) {
        getAllActivityData(limit, page + 1);
        setPage((o) => o + 1);
      }
    }
  };

  const getDate = (date) => {
    const dateArray = dayjs(date).format(DEFAULT_DATETIME_FORMAT).split(' ')
    return `${dateArray[0]} at ${dateArray[1]} ${dateArray[2]}`
  }

  function getList(item) {
    switch (item.event) {
      case ACTIONS.CREATE:
        return (
          <>
            <div
              className="named_picture me-2"
              style={{ fontWeight: "bolder" }}
            >
              {getInitials(item?.user?.name)}
            </div>

            <div className="activity_status">
              <span>
                {item?.user?.name} has created a{" "}
                <span dangerouslySetInnerHTML={{ __html: item?.payload?.actual_updated_property }} />
              </span>
              {item?.payload?.name ? (
                <>
                  <span>&nbsp;for</span>
                  <span className="activity_user">
                    &nbsp;{item?.payload?.name}
                  </span>
                </>
              ) : item?.payload?.created_property_name ? (
                <>
                  <span>&nbsp;with name</span>
                  <span className="activity_user">
                    &nbsp;{item?.payload?.created_property_name}
                  </span>
                </>
              ) : (
                " "
              )}
              &nbsp;on &nbsp;<span>{getDate(item?.created_at)}</span>
            </div>
          </>
        );
      case ACTIONS.EDIT:
        return (
          <>
            {item?.payload?.actual_updated_property ? (
              <>
                <div
                  className="named_picture me-2"
                  style={{ fontWeight: "bolder" }}
                >
                  {getInitials(item?.user?.name)}
                </div>

                <div style={{ width: "90%" }} className="activity_status">
                  <span>
                    {item?.user?.name} has updated{" "}
                    {item?.module === ALL_MODULES.PG_VACANT_REQUESTS
                      ? removeSpecial(item.payload.field_name) : <span dangerouslySetInnerHTML={{ __html: item?.payload?.actual_updated_property }} />}{" "}
                  </span>
                  {item?.payload?.old_value ? (
                    <span>
                      {" "}
                      from{" "}
                      <span className="activity_status_name">
                        <b>{item?.payload?.old_value}</b>
                      </span>{" "}
                      {item?.payload?.new_value ? (
                        <>
                          to{" "}
                          <span className="activity_status_name">
                            <b>{JSON.stringify(item?.payload?.new_value)}</b>{" "}
                          </span>
                        </>
                      ) : (
                        " "
                      )}
                    </span>
                  ) : item?.payload?.new_value ? (
                    <span className="inside_activity">
                      to <b>{JSON.stringify(item?.payload?.new_value)} </b>
                    </span>
                  ) : (
                    " "
                  )}
                  {item?.payload?.name ? (
                    <>
                      <span>for </span>
                      <span
                        style={{
                          fontWeight: "bolder",
                        }}
                        className="inside_activity"
                      >
                        {item?.payload?.name}{" "}
                      </span>
                    </>
                  ) : (
                    " "
                  )}
                  {item?.payload?.created_property_name ? (
                    <>
                      <span>with name </span>
                      <span
                        style={{
                          fontWeight: "bolder",
                        }}
                        className="inside_activity"
                      >
                        {item?.payload?.created_property_name}{" "}
                      </span>
                    </>
                  ) : (
                    " "
                  )}
                  <span>
                    on {getDate(item?.created_at)}
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        );
      default:
    }
  }

  return (
    <div
      id='activities-page'
      className='page-content'
      style={{ maxHeight: '100vh' }}
    >
      <Container fluid onScroll={scrollHandler}>
        <div className="d-flex justify-content-between">
          <h4 className="card-title mb-4" style={{ color: '#565656' }}>Activities</h4>
          <div className="mb-2">
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={label}
              onChange={handleChange}
              MenuProps={MenuProps}
              size="small"
            >
              {Object.values(TIME_INTERVAL).map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            {isShowDatePicker &&
              (<div
                className="d-flex flex-column"
                style={{ marginLeft: '-8.7rem', zIndex: 1, position: 'absolute' }}
                onMouseLeave={() => setIsShowDatePicker(false)}
              >
                <DateRange
                  editableDateInputs={true}
                  onChange={item => setSelectionRange([item.selection])}
                  moveRangeOnFirstSelection={true}
                  ranges={selectionRange}
                />
                <Button
                  className="yellow_gradient_btn"
                  onClick={handleFilter}>
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-1"
                    />
                  ) : 'Filter'}
                </Button>
              </div>)
            }
          </div>
        </div>
        <div className="latest_activities">
          <div
            className="inner_pg_content"
            style={{ maxHeight: '80vh' }}
            onScroll={scrollHandler}
          >
            {timeLineData?.length === 0 && isLoading && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />
              </div>
            )}

            {timeLineData?.length === 0 && !isLoading && (
              <div style={{ textAlign: 'center' }}>
                <NodataFound text='No activities to show' icon={NoDataIcon} />
              </div>
            )}
            {timeLineData.length > 0 && (<Card className="shadow-none border">
              <div style={{ margin: '8px 0px -9px 8px' }}>
                <ul className="ps-0">
                  {timeLineData.map((item, index) => (
                    <li key={index} className="list-inline d-flex align-items-start">
                      {getList(item)}
                    </li>
                  ))}
                </ul>
              </div>
            </Card>)}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Activities;
