import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BasicDetails from '../../components/ActiveProperties/ActivePropertiesDetail/basicDetails';
// import TenantsDetail from '../../components/ActiveProperties/ActivePropertiesDetail/tenantDetails';
import BedMatrixDetails from '../../components/ActiveProperties/ActivePropertiesDetail/bedMatrix';
// import RentSlipDetails from '../../components/ActiveProperties/ActivePropertiesDetail/rentSlip';
// import CommissionDetails from '../../components/ActiveProperties/ActivePropertiesDetail/commissionSlip';
import ActivityTimeline from "../../components/ActiveProperties/ActivePropertiesDetail/activityTimeline";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getPropertyDetails } from '../../api/property';
import { toast } from 'react-toastify';
import { ALL_MODULES } from '../../common/constant';
import PropertyReviews from '../../components/ActiveProperties/ActivePropertiesDetail/PropertyReviews';
import { NOTIFICATION_EVENTS } from '../../constants/variables';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  const { detailValue } = props;
  //const { propertyType: detailValue} = useParams();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{React.cloneElement(children, { detailValue })}</Typography>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function ActivePropertiesDetails() {
  const [value, setValue] = React.useState(0);
  const [navigateState] =useState(useLocation()?.state);
  const [propertyDetails, setPropertyDetails] = useState();
  const navigate = useNavigate();
  const { propertyType: detailValue, property: propertyId } = useParams();
  useEffect(() => {
    fetchPropertyDetails()
    if (navigateState?.bed_type) setValue(1);
  }, [])

  const fetchPropertyDetails = async () => {
    try {
      const res = (await getPropertyDetails(parseInt(propertyId))).data.data
      setPropertyDetails(res);
      if ([NOTIFICATION_EVENTS.PROPERTY_REVIEW_CREATE, NOTIFICATION_EVENTS.OWNER_REPLY_TO_REVIEW_ADMIN_PORTAL].includes(navigateState?.event)) setValue(3);
    } catch (err) {
      console.log(err)
      toast.error(err?.response?.data?.message)
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBack = () => {
    navigate('/active-properties/active-properties-live');
  };
  return (
    <div className="page-content">
      <div className="container-fluid">
        {/* Render Breadcrumb */}
        <div className="d-flex">
          <ArrowBackOutlinedIcon style={{ marginRight: '10px', cursor: 'pointer', marginBottom: '10px' }} onClick={handleBack} />
          {/* <Breadcrumbs title="Active Properties | Dstayz" breadcrumbItem="Bed Arrangements" /> */}
        </div>
        {/* Tabs starts here */}
        <div className='active-property-main-detail'>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='aPTabs'>
                <Tab label="Basic Details" {...a11yProps(0)} />
                {/* <Tab label="Tenants Details" {...a11yProps(1)} /> */}
                <Tab label="Bed Matrix" {...a11yProps(1)} />
                {/* <Tab label="Rent Slip" {...a11yProps(3)} />
                <Tab label="Commission Slip" {...a11yProps(4)} /> */}
                <Tab label="Activity Timeline" {...a11yProps(2)} />
                <Tab label="Reviews" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <BasicDetails propertyDetails={propertyDetails} />
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={1}>
              <TenantsDetail />
            </CustomTabPanel> */}
            <CustomTabPanel value={value} index={1} propertyDetailValue={detailValue}>
              <BedMatrixDetails propertyType={detailValue} propertyDetails={propertyDetails} />
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={3}>
              <RentSlipDetails />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <CommissionDetails />
            </CustomTabPanel> */}
            <CustomTabPanel value={value} index={2}>
              <ActivityTimeline module={ALL_MODULES.PROPERTIES} module_id={propertyId} activityCompHeight={62}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <PropertyReviews propertyId={propertyDetails?.id} reviewCompHeight={70}/>
            </CustomTabPanel>
          </Box>
        </div>
      </div>
    </div>
  )
}