import React, { useEffect, useState } from 'react';

import {
  Row, Col, CardBody, Card, Container, Form, Input, Label, FormFeedback,
} from 'reactstrap';

import { loginApi, userDetailsApi } from '../../api/User/login';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// import images
import logoDark from '../../assets/images/logo1.png';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { storeUserDetails } from '../../store/auth';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { PORTAL } from '../../common/constant';
import { FormHelperText, IconButton, InputAdornment, OutlinedInput, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { handleKeyDown } from '../../helpers/string_helper';

function Login() {
  const [loginType, setLoginType] = useState('email');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  document.title = ' Login | DStayZ';

  // Password show/hide
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleChange = (event) => {
    setLoginType(event.target.value);
  };

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
      contact_number: '',
      loginType,
    },
    validationSchema: Yup.object({
      loginType: Yup.string().optional(),
      email: Yup.string().when('loginType', {
        is: 'email',
        then: () => Yup.string().email().required(),
        // otherwise: () =>Yup.optional(),
      }),
      contact_number: Yup.string().when('loginType', {
        is: 'contactNo',
        then: () => Yup.string().matches(/^[0-9]{10}$/, 'Contact number must be 10 digits').required(),
      }),
      password: Yup.string().required('Please Enter Your Password')
    }),
    onSubmit: async (values) => {
      try {
        const token = await loginApi({
          portal: PORTAL.BACKOFFICE_PORTAL,
          ...(loginType === 'email' && {
            email: values.email,
            isEmail: true
          }),
          password: values.password,
          ...(loginType === 'contactNo' && {
            contact_number: values.contact_number,
            isEmail: false
          })
        })
        let userDetails
        if (token) {
          localStorage.setItem('token', token)
          userDetails = await userDetailsApi()
          dispatch(storeUserDetails({ user: userDetails }))
          if (userDetails?.user?.is_password_reset) {
            navigate("/dashboard")
          } else {
            navigate("/reset-password")
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  });

  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });
  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>

        <Row className="align-items-center justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card>
              <Row>
                <Col lg={12}>
                  <div className="text-center">
                    <Link to="/" className="mt-2 d-block auth-logo">
                      <img src={logoDark} alt="" height="48" className="logo logo-dark" />
                    </Link>
                  </div>
                </Col>
              </Row>

              <CardBody>
                <div className="text-center">
                  <h5 className="text-primary">Welcome Back !</h5>
                  <p className="text-muted">Sign in to continue to DStayZ.</p>
                </div>
                <div className="p-2 mt-4">
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <FormControl>
                      <FormLabel id="demo-controlled-radio-buttons-group">Login with</FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={loginType}
                        onClick={handleChange}
                      >
                        <FormControlLabel value="email" control={<Radio />} label="Email" />
                        <FormControlLabel value="contactNo" control={<Radio />} label="Phone Number" />
                      </RadioGroup>
                    </FormControl>
                    {loginType === 'email' ? (
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <TextField
                          name="email"
                          className="form-control"
                          placeholder="Enter Email"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          error={
                            !!(validation.touched.email && validation.errors.email)
                          }
                          size='small'
                        />
                        {validation.touched.email && validation.errors.email && (
                          <FormHelperText error id="email-error">
                            {validation.errors.email}
                          </FormHelperText>
                        )}
                      </div>
                    ) : (
                      <div className="mb-3">
                        <Label className="form-label">Phone Number</Label>
                        <TextField
                          name="contact_number"
                          className="form-control"
                          placeholder="Enter Phone Number"
                          onChange={validation.handleChange}
                          type="text"
                          onBlur={validation.handleBlur}
                          value={validation.values.contact_number || ''}
                          onKeyDown={handleKeyDown}
                          error={
                            !!(validation.touched.contact_number && validation.errors.contact_number)
                          }
                          size='small'
                        />
                        {validation.touched.contact_number && validation.errors.contact_number ? (
                          <FormHelperText type="invalid">{validation.errors.contact_number}</FormHelperText>
                        ) : null}
                      </div>
                    )}

                    <div className="mb-3">
                      <div className="float-end">
                        <Link to="/recover-password" className="text-muted">Forgot password?</Link>
                      </div>
                      <Label className="form-label">Password</Label>
                      <OutlinedInput
                        name="password"
                        placeholder="Enter Password"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ''}
                        error={!!(validation.touched.password && validation.errors.password)}
                        size='small'
                        endAdornment={
                          <InputAdornment position="center">
                            <IconButton
                              aria-label={
                                showPassword ? 'hide the password' : 'display the password'
                              }
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {!!(validation.touched.password && validation.errors.password) && (
                        <FormHelperText error id="accountId-error">
                          {validation.errors.password}
                        </FormHelperText>
                      )}
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customControlInline"
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="mt-3 text-end">
                      <button
                        className="btn btn-primary w-sm waves-effect waves-light"
                        type="submit"
                        disabled={!validation.isValid}
                      >
                        Log In
                      </button>
                    </div>
                    <div className="mt-4 text-center">
                      <p className="mb-0">
                        Don&apos;t have an account ?
                        <a href="/register" className="fw-medium text-primary"> Signup now </a>
                      </p>
                    </div>
                  </Form>
                </div>

              </CardBody>
            </Card>

          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;
