import React, { useState } from 'react';
import {
  Button,
  Form,
} from 'reactstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as Yup from 'yup';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useFormik } from 'formik';
import { editCMS } from '../../../api/GlobalSettings/api';
import { IconButton, TextField } from '@mui/material';

export default function SocialMediaLinks({ page, tabDetails, refresh }) {
  const [isLoading, setIsLoading] = useState(false);
  const [enableField, setEnabledField] = useState({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      header: tabDetails?.header,
      links: {
        facebook: tabDetails?.links?.facebook,
        instagram: tabDetails?.links?.instagram,
        linkedin: tabDetails?.links?.linkedin,
        x: tabDetails?.links?.x,
        youtube: tabDetails?.links?.youtube,
      }
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      header: Yup.string().optional(),
      links: Yup.object({
          facebook: Yup.string().optional(),
          instagram: Yup.string().optional(),
          linkedin: Yup.string().optional(),
          x: Yup.string().optional(),
          youtube: Yup.string().optional(),
        })

    }),
    onSubmit: async (payload) => {
      try {
        setIsLoading(true)
        const success = await editCMS({ page, ...payload });
        if (success)
          refresh();
      } catch (error) {
        console.log(error);
      }
      finally {
        setIsLoading(false)
      }
    },
  });

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}>

      {/* Main Image */}
      <div className='d-flex justify-content-between align-items-center'>
        <div className='custom-image-upload' style={{ alignItems: 'center' }}>
        </div>
        <Button
          className="yellow_gradient_btn"
          type='submit'
          disabled={!formik.isValid || isLoading}
        >Save</Button>
      </div >

      {/* Header */}
      <div className='my-3' >
        <h4 style={{ fontWeight: '600', fontSize: '16px' }}>
          Header
        </h4>
        <div className='d-flex align-items-center'>
          <TextField
            name='header'
            className='mb-2'
            value={formik?.values?.header}
            onChange={formik.handleChange}
            disabled={!enableField[`header`]}
            label='Header'
            fullWidth
            type="text"
            onBlur={formik.handleBlur}
            variant="outlined"
            size="small"
            error={
              !!(formik?.touched?.header && formik?.errors?.header)
            }
            helperText={formik.errors?.header}
          />
          <div className='d-flex flex-column'>
            <IconButton
              size='small'
              variant='contained'
              onClick={(prev) => {
                setEnabledField({ ...prev, ['header']: !enableField['header'] })
              }}
            >
              {!enableField['header'] ?
                <EditOutlinedIcon />
                : <CancelOutlinedIcon />
              }
            </IconButton>
          </div>
        </div>
      </div >
      {/* Facebook */}
      <div className='my-3' >
        <h4 style={{ fontWeight: '600', fontSize: '16px' }}>
          Facebook
        </h4>
        <div className='d-flex align-items-center'>
          <TextField
            name='links.facebook'
            className='mb-2'
            value={formik?.values?.links?.facebook}
            onChange={formik.handleChange}
            required
            disabled={!enableField[`facebook`]}
            label='Enter Facebook Link'
            fullWidth
            type="text"
            onBlur={formik.handleBlur}
            variant="outlined"
            size="small"
            error={
              !!(formik?.touched?.links?.facebook && formik?.errors?.links?.facebook)
            }
            helperText={formik.errors?.links?.facebook}
          />
          <div className='d-flex flex-column'>
            <IconButton
              size='small'
              variant='contained'
              onClick={(prev) => {
                setEnabledField({ ...prev, ['facebook']: !enableField['facebook'] })
              }}
            >
              {!enableField['facebook'] ?
                <EditOutlinedIcon />
                : <CancelOutlinedIcon />
              }
            </IconButton>
          </div>
        </div>
      </div >
      {/* Instagram */}
      <div className='my-3' >
        <h4 style={{ fontWeight: '600', fontSize: '16px' }}>
          Instagram
        </h4>
        <div className='d-flex align-items-center'>
          <TextField
            name='links.instagram'
            className='mb-2'
            value={formik?.values?.links?.instagram}
            onChange={formik.handleChange}
            required
            disabled={!enableField[`instagram`]}
            label='Enter Instagram Link'
            fullWidth
            type="text"
            onBlur={formik.handleBlur}
            variant="outlined"
            size="small"
            error={
              !!(formik?.touched?.links?.instagram && formik?.errors?.links?.instagram)
            }
            helperText={formik.errors?.links?.instagram}
          />
          <div className='d-flex flex-column'>
            <IconButton
              size='small'
              variant='contained'
              onClick={(prev) => {
                setEnabledField({ ...prev, ['instagram']: !enableField['instagram'] })
              }}
            >
              {!enableField['instagram'] ?
                <EditOutlinedIcon />
                : <CancelOutlinedIcon />
              }
            </IconButton>
          </div>
        </div>
      </div >
      {/* LinkedIn */}
      <div className='my-3' >
        <h4 style={{ fontWeight: '600', fontSize: '16px' }}>
          LinkedIn
        </h4>
        <div className='d-flex align-items-center'>
          <TextField
            name='links.linkedin'
            className='mb-2'
            value={formik?.values?.links?.linkedin}
            onChange={formik.handleChange}
            required
            disabled={!enableField[`linkedin`]}
            label='Enter LinkedIn Link'
            fullWidth
            type="text"
            onBlur={formik.handleBlur}
            variant="outlined"
            size="small"
            error={
              !!(formik?.touched?.links?.linkedin && formik?.errors?.links?.linkedin)
            }
            helperText={formik.errors?.links?.linkedin}
          />
          <div className='d-flex flex-column'>
            <IconButton
              size='small'
              variant='contained'
              onClick={(prev) => {
                setEnabledField({ ...prev, ['linkedin']: !enableField['linkedin'] })
              }}
            >
              {!enableField['linkedin'] ?
                <EditOutlinedIcon />
                : <CancelOutlinedIcon />
              }
            </IconButton>
          </div>
        </div>
      </div >
      {/* X */}
      <div className='my-3' >
        <h4 style={{ fontWeight: '600', fontSize: '16px' }}>
          X
        </h4>
        <div className='d-flex align-items-center'>
          <TextField
            name='links.x'
            className='mb-2'
            value={formik?.values?.links?.x}
            onChange={formik.handleChange}
            required
            disabled={!enableField[`x`]}
            label='Enter X Link'
            fullWidth
            type="text"
            onBlur={formik.handleBlur}
            variant="outlined"
            size="small"
            error={
              !!(formik?.touched?.links?.x && formik?.errors?.links?.x)
            }
            helperText={formik.errors?.links?.x}
          />
          <div className='d-flex flex-column'>
            <IconButton
              size='small'
              variant='contained'
              onClick={(prev) => {
                setEnabledField({ ...prev, ['x']: !enableField['x'] })
              }}
            >
              {!enableField['x'] ?
                <EditOutlinedIcon />
                : <CancelOutlinedIcon />
              }
            </IconButton>
          </div>
        </div>
      </div >
      {/* YouTube */}
      <div className='my-3' >
        <h4 style={{ fontWeight: '600', fontSize: '16px' }}>
          YouTube
        </h4>
        <div className='d-flex align-items-center'>
          <TextField
            name='links.youtube'
            className='mb-2'
            value={formik?.values?.links?.youtube}
            onChange={formik.handleChange}
            required
            disabled={!enableField[`youtube`]}
            label='Enter YouTube Link'
            fullWidth
            type="text"
            onBlur={formik.handleBlur}
            variant="outlined"
            size="small"
            error={
              !!(formik?.touched?.links?.youtube && formik?.errors?.links?.youtube)
            }
            helperText={formik.errors?.links?.youtube}
          />
          <div className='d-flex flex-column'>
            <IconButton
              size='small'
              variant='contained'
              onClick={(prev) => {
                setEnabledField({ ...prev, ['youtube']: !enableField['youtube'] })
              }}
            >
              {!enableField['youtube'] ?
                <EditOutlinedIcon />
                : <CancelOutlinedIcon />
              }
            </IconButton>
          </div>
        </div>
      </div >
    </Form >
  );
}
