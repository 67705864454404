import React, { useState, useEffect } from "react";
import {
    Modal, ModalHeader, ModalBody,
    ListGroup, ListGroupItem,
    Button,
} from 'reactstrap';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { FormControlLabel, RadioGroup, Radio, } from '@mui/material';
import { getPropertyDetails, updateCustomDepositMoney, updateCustomDepositType } from "../../api/property";
import { toast } from "react-toastify";
import { ACTIONS, CAUTION_MONEY_TYPE, PROPERTY_STATUS, SOCKET_EVENT } from "../../common/constant";
import EditIcon from '@mui/icons-material/Edit';
import { socket } from "../../utils/socket";
import ConfirmationModal from "../Common/ConfirmationModal";

export default function CautionMoneyModal({
    open,
    handleClose,
    propertyId,
}) {
    const [selectedValue, setSelectedValue] = useState("");
    const [expanded, setExpanded] = useState();
    const [openAccordion, setOpenAccordion] = useState("");
    const [customDeposit, setCustomDeposit] = useState();
    const [isConfirm, setIsConfirm] = useState(false);
    const [modal, setModal] = useState(true);

    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? "" : id);
    };
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const [flatexpanded, setFlatExpanded] = useState();

    const flathandleChange = (panel) => (event, newExpanded) => {
        setFlatExpanded(newExpanded ? panel : false);
    };
    const [roomexpanded, setRoomExpanded] = React.useState();
    const [fixedRate, setFixedRate] = useState()

    const roomhandleChange = (panel) => (event, newExpanded) => {
        setRoomExpanded(newExpanded ? panel : false);
    };
    const [propertyDetails, setPropertyDetails] = useState()


    useEffect(() => {
        fetchPropertyDetails()
    }, [])

    const fetchPropertyDetails = async () => {
        try {
            const res = (await getPropertyDetails(propertyId?.id, { type: 'backoffice' })).data.data
            setSelectedValue(res?.caution_money_type)
            res?.caution_money_type === CAUTION_MONEY_TYPE.FIXED_RATE && setFixedRate(res?.fixed_rate)
            let sortedFloor = []
            if (res?.floors?.length > 0) {
                sortedFloor = res?.floors.toSorted((a, b) => a.name > b.name ? 1 : -1).map((it) => it);
            }

            setPropertyDetails({
                ...res,
                floors: sortedFloor
            });
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }

    const handleRadioChange = async (value) => {
        setExpanded(false);
        setFlatExpanded(false);
        setRoomExpanded(false);
        setFixedRate()
        setCustomDeposit()
        if (value !== selectedValue && value !== propertyDetails?.caution_money_type && value !== CAUTION_MONEY_TYPE.FIXED_RATE) {
            setIsConfirm(true)
            setModal(false)
        }
        setSelectedValue(value);
    };

    const handleFixCustomType = async () => {
        try {
            const payload = {
                caution_money_type: selectedValue,
                ...(selectedValue === CAUTION_MONEY_TYPE.FIXED_RATE && {
                    fixed_rate: fixedRate
                })
            }
            const res = await updateCustomDepositType(propertyId?.id, payload)
            if (res.status === 200) {
                setPropertyDetails({ ...propertyDetails, ...payload })
                if (propertyId?.status === PROPERTY_STATUS.LIVE) {
                    socket.emit(SOCKET_EVENT.CUSTOM_DEPOSIT_SEND, { property_id: propertyId?.id, ...payload, status: propertyId?.status })
                }
                toast.success(res.data.message)
            } else {
                toast.error(res.data.message)
            }
        } catch (e) {
            toast.error(e.response.data.message)
        } finally {
            setModal(true)
            setIsConfirm(false)
        }
    }

    const handleSubmit = async (id, floorId, flatId = '', roomId) => {
        try {
            const res = await updateCustomDepositMoney(id, { caution_money: customDeposit?.value })
            if (res.status === 200) {
                if (propertyId?.status === PROPERTY_STATUS.LIVE) {
                    socket.emit(SOCKET_EVENT.CUSTOM_DEPOSIT_SEND, { property_id: propertyId?.id, caution_money_type: selectedValue, status: propertyId?.status, bed_id: id, room_id: roomId, caution_money: customDeposit?.value })
                }
                const result = propertyDetails?.floors?.map((floor) => {
                    if (floor?.id === floorId) {
                        if (floor?.flats?.length > 0) {
                            const flatData = floor?.flats?.map((flat) => {
                                if (flat?.id === flatId) {
                                    return {
                                        ...flat,
                                        rooms: flat?.rooms?.map((room) => {
                                            if (room?.id === roomId) {
                                                const beds = room?.beds.map((bed) => {
                                                    if (bed?.id === id) {
                                                        return {
                                                            ...bed,
                                                            caution_money: customDeposit?.value
                                                        }
                                                    } else {
                                                        return bed
                                                    }
                                                })

                                                return {
                                                    ...room,
                                                    beds: beds
                                                }
                                            } else {
                                                return room
                                            }
                                        })
                                    }
                                } else {
                                    return flat
                                }
                            })
                            return {
                                ...floor,
                                flats: flatData
                            }
                        } else {
                            return {
                                ...floor,
                                rooms: floor?.rooms?.map((room) => {
                                    if (room?.id === roomId) {
                                        const findBed = room?.beds.find((bed) => bed?.id === id)
                                        return {
                                            ...room,
                                            beds: [
                                                ...room.beds,
                                                { ...findBed, caution_money: customDeposit.value }
                                            ]

                                        }
                                    } else {
                                        return room
                                    }
                                })
                            }
                        }
                    } else {
                        return floor
                    }
                })
                setPropertyDetails({
                    ...propertyDetails,
                    floors: result
                })
                setCustomDeposit()
                toast.success(res.data.message)
            } else {
                toast.error(res.data.message)
            }
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }

    return (
        <div>
            {modal && (
                <Modal isOpen={open} toggle={handleClose} centered size="lg" style={{ maxWidth: '85%', width: '85%' }}>
                    <ModalHeader toggle={handleClose}>
                        Configure Caution Money
                    </ModalHeader>
                    <ModalBody>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={selectedValue}
                            >
                                <FormControlLabel onClick={() => { handleRadioChange(CAUTION_MONEY_TYPE.EQUAL_TO_BED_RATE) }} value="equal_to_bed_rent" control={<Radio />} label="Equal to Bed Rent" />
                                <FormControlLabel onClick={() => { handleRadioChange(CAUTION_MONEY_TYPE.FIXED_RATE) }} value="fixed_rate" control={<Radio />} label="Fixed Rate" />
                                <FormControlLabel onClick={() => { handleRadioChange(CAUTION_MONEY_TYPE.CUSTOM) }} value="custom" control={<Radio />} label="Custom" />
                            </RadioGroup>
                        </FormControl>

                        {([CAUTION_MONEY_TYPE.EQUAL_TO_BED_RATE, CAUTION_MONEY_TYPE.FIXED_RATE, CAUTION_MONEY_TYPE.CUSTOM].includes(selectedValue)) && (
                            <div>
                                {selectedValue === CAUTION_MONEY_TYPE.FIXED_RATE &&
                                    <div className='d-flex align-items-center gap-2'>
                                        <div style={{ width: '150px' }}>
                                            <FormControl>
                                                <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-amount"
                                                    startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                                    label="Amount"
                                                    value={fixedRate}
                                                    onChange={(event) => setFixedRate(event?.target.value)}
                                                />
                                            </FormControl>
                                        </div>
                                        <div><Button className="yellow_gradient_btn mx-2 btn btn-secondary" disabled={!fixedRate || fixedRate === propertyDetails?.fixed_rate} onClick={handleFixCustomType} >Save</Button></div>
                                    </div>
                                }
                                <div className='accordione_scroll'>
                                    {propertyDetails?.floors?.length > 0 && propertyDetails?.floors?.map((floor, index) => (
                                        <div className="custom-accordion" key={index}>
                                            <Accordion expanded={expanded === `panel${index + 1}`} onChange={handleChange(`panel${index + 1}`)} toggle={() => toggleAccordion(floor?.id)} sx={{ marginBottom: '12px' }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1-content"
                                                    id="panel1-header"
                                                >
                                                    Floor {floor?.name}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className='d-flex gap-2'>
                                                        {floor?.flats?.length > 0 ? (floor?.flats?.map((flat, flatIndex) => (
                                                            <div className="flatcustom-accordion" key={flatIndex}>
                                                                <Accordion expanded={flatexpanded === `flatexpand${flatIndex + 1}`} onChange={flathandleChange(`flatexpand${flatIndex + 1}`)}>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1-content"
                                                                        id="panel1-header"
                                                                    >
                                                                        {flat?.name}
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <div className='scroll-container'>
                                                                            {flat?.rooms?.length > 0 && flat?.rooms?.map((room, rmIndex) => (
                                                                                <div className='mb-2 roomOne_background' key={rmIndex}>
                                                                                    <Accordion expanded={roomexpanded === `roomexpand${rmIndex + 1}`} onChange={roomhandleChange(`roomexpand${rmIndex + 1}`)} sx={{ marginBottom: '12px' }}>
                                                                                        <AccordionSummary
                                                                                            expandIcon={<ExpandMoreIcon />}
                                                                                            aria-controls="panel1-content"
                                                                                            id="panel1-header"
                                                                                        >
                                                                                            {room?.name}
                                                                                        </AccordionSummary>
                                                                                        <AccordionDetails>
                                                                                            <div>
                                                                                                <ListGroup flush>
                                                                                                    {room?.beds?.length > 0 && room?.beds?.map((bed, bdIndex) => (
                                                                                                        <ListGroupItem
                                                                                                            href="#"
                                                                                                            tag="a"
                                                                                                            className='p-2'
                                                                                                            key={bdIndex}
                                                                                                        >
                                                                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                                                                <div><p className='mb-0'>{bed?.name}</p></div>
                                                                                                                <div className='d-flex gap-2 align-items-center'>
                                                                                                                    <div style={{ width: '150px' }}>
                                                                                                                        <FormControl>
                                                                                                                            <div className="d-flex">
                                                                                                                                <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                                                                                                                                <OutlinedInput
                                                                                                                                    id="outlined-adornment-amount"
                                                                                                                                    startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                                                                                                                    label="Amount"
                                                                                                                                    type="number"
                                                                                                                                    name={`bed.${bed.id}.caution_money`}
                                                                                                                                    onChange={(event) => setCustomDeposit({
                                                                                                                                        id: bed?.id,
                                                                                                                                        value: event.target.value
                                                                                                                                    })}
                                                                                                                                    value={selectedValue === CAUTION_MONEY_TYPE.CUSTOM ? (customDeposit?.id === bed?.id ? customDeposit?.value : (bed?.caution_money > 0 ? bed?.caution_money : bed?.price)) : bed?.price}
                                                                                                                                    disabled={propertyDetails?.caution_money_type !== CAUTION_MONEY_TYPE.CUSTOM}
                                                                                                                                />
                                                                                                                                {propertyDetails?.caution_money_type === CAUTION_MONEY_TYPE.CUSTOM && (
                                                                                                                                    <EditIcon className="mt-2" style={(customDeposit?.id === bed?.id && customDeposit?.value) ? { cursor: 'pointer' } : { cursor: 'not-allowed' }} color="red" onClick={() => handleSubmit(bed.id, floor.id, flat.id, room.id)} />
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                        </FormControl>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </ListGroupItem>
                                                                                                    ))}
                                                                                                </ListGroup>
                                                                                            </div>
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </div>
                                                        ))) : (
                                                            floor?.rooms?.length > 0 && floor?.rooms?.map((room, rmIndex) => (
                                                                <div className='mb-2 roomOne_background' key={rmIndex}>
                                                                    <Accordion expanded={roomexpanded === `roomexpand${rmIndex + 1}`} onChange={roomhandleChange(`roomexpand${rmIndex + 1}`)} sx={{ marginBottom: '12px' }}>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1-content"
                                                                            id="panel1-header"
                                                                        >
                                                                            {room?.name}
                                                                        </AccordionSummary>
                                                                        <AccordionDetails>
                                                                            <div>
                                                                                <ListGroup flush>
                                                                                    {room?.beds?.length > 0 && room?.beds?.map((bed, bdIndex) => (
                                                                                        <ListGroupItem
                                                                                            href="#"
                                                                                            tag="a"
                                                                                            className='p-2'
                                                                                            key={bdIndex}
                                                                                        >
                                                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                                                <div><p className='mb-0'>{bed?.name}</p></div>
                                                                                                <div className='d-flex gap-2 align-items-center'>
                                                                                                    <div style={{ width: '150px' }}>
                                                                                                        <FormControl>
                                                                                                            <div className="d-flex">
                                                                                                                <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                                                                                                                <OutlinedInput
                                                                                                                    id="outlined-adornment-amount"
                                                                                                                    startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                                                                                                    label="Amount"
                                                                                                                    type="number"
                                                                                                                    name={`bed.${bed.id}.caution_money`}
                                                                                                                    onChange={(event) => setCustomDeposit({
                                                                                                                        id: bed?.id,
                                                                                                                        value: event.target.value
                                                                                                                    })}
                                                                                                                    value={selectedValue === CAUTION_MONEY_TYPE.CUSTOM ? (customDeposit?.id === bed?.id ? customDeposit?.value : (bed?.caution_money > 0 ? bed?.caution_money : bed?.price)) : bed?.price}
                                                                                                                    disabled={propertyDetails?.caution_money_type !== CAUTION_MONEY_TYPE.CUSTOM}
                                                                                                                />
                                                                                                                {propertyDetails?.caution_money_type === CAUTION_MONEY_TYPE.CUSTOM && (
                                                                                                                    <EditIcon className="mt-2" style={(customDeposit?.id === bed?.id && customDeposit?.value) ? { cursor: 'pointer' } : { cursor: 'not-allowed' }} color="red" onClick={() => handleSubmit(bed.id, floor.id, '', room.id)} />
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </FormControl>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </ListGroupItem>
                                                                                    ))}
                                                                                </ListGroup>
                                                                            </div>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </div>
                                                            ))
                                                        )}
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </ModalBody>
                </Modal>
            )}

            {isConfirm && (
                <ConfirmationModal
                    action={ACTIONS.EDIT}
                    show={isConfirm}
                    onCloseClick={() => {
                        setIsConfirm(false)
                        setModal(true)
                        setSelectedValue(propertyDetails?.caution_money_type)
                    }}
                    onAcceptClick={handleFixCustomType}
                />
            )}
        </div>
    );
}
