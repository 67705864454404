import React, { useState, useEffect } from 'react';
import { Card, CardBody, Table, Row, Col } from 'reactstrap';
import { BILL_PAID_STATUS, DEFAULT_DATE_FORMAT, PAYMENT_TYPES, PER_PAGE } from '../../common/constant';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../Common/Loader";
import NodataFound from '../Common/NoDataFound';
import { Td, Tr } from 'react-super-responsive-table';
import NoDataIcon from '../../assets/images/no-data.png';
import { getAllRefundDeposit } from '../../api/deposit-refund';
import dayjs from 'dayjs';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

export function LatestTransactionRefund({ tableHeaderBg, tableColor }) {
  const [refundDepositList, setrRefundDepositList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorElPaid, setAnchorElPaid] = useState(null)
  const [id, setId] = useState(null);

  const handleClickForPaid = (event, item) => {
    setId(item.id)
    setAnchorElPaid(event.currentTarget);
  };
  const handleCloseForPaid = () => {
    setId()
    setAnchorElPaid(null);
  };
  const showInfo = Boolean(anchorElPaid)

  useEffect(() => {
    fetchAllRefundDepositSlip()
  }, [])

  const fetchAllRefundDepositSlip = async () => {
    try {
      setIsLoading(true);
      let params = {
        page: page,
        limit: PER_PAGE[0],
        filter_fields: ['payment'],
        filter_inputs: [`${PAYMENT_TYPES.DEPOSIT_REFUND}, ${BILL_PAID_STATUS.PAID}`]
      }
      const response = (await getAllRefundDeposit(params)).data.data;
      setHasMore(response.meta.current_page !== response.meta.last_page)
      setrRefundDepositList((prevState) => {
        return [...prevState, ...response.data];
      });
      setPage((prevState) => prevState + 1);
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }


  return (
    <Row>
      <Col lg={12}>
        <Card style={{ borderRadius: '8px', overflow: 'hidden' }}>
          <CardBody className='p-0'>
            {!refundDepositList.length && !isLoading ? (
              <NodataFound
                icon={NoDataIcon}
                style={{
                  height: "210px",
                  overflow: refundDepositList.length ? "auto" : "hidden",
                }}
                text={`No deposit refund to show`} />
            ) : (
              <div
                style={{
                  height: "280px",
                  overflow: refundDepositList.length ? "auto" : "hidden",
                }}
              >
                <InfiniteScroll
                  dataLength={refundDepositList.length}
                  next={fetchAllRefundDepositSlip}
                  hasMore={hasMore}
                  height={refundDepositList?.length ? "280px" : "auto"}
                  loader={isLoading && <Loader />}
                >
                  <div className="dashboard_table">
                    <Table className="table-centered table-nowrap mb-0">
                      <thead className="table-light">
                        <tr>
                          <th className='font-size-13' style={{ backgroundColor: tableHeaderBg, color: tableColor }}>SI No</th>
                          <th className='font-size-13' style={{ backgroundColor: tableHeaderBg, color: tableColor }}>Refund Date</th>
                          <th className='font-size-13' style={{ backgroundColor: tableHeaderBg, color: tableColor }}>Property Name</th>
                          <th className='font-size-13' style={{ backgroundColor: tableHeaderBg, color: tableColor }}>Tenant Name</th>
                          <th className='font-size-13' style={{ backgroundColor: tableHeaderBg, color: tableColor }}>Refunded Amount</th>
                          <th className='font-size-13' style={{ backgroundColor: tableHeaderBg, color: tableColor }}>Refund status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {refundDepositList?.length > 0 && refundDepositList.map((item, index) => (
                          <Tr className='font-size-13' key={index}>
                            <Td>{++index}</Td>
                            <Td> {item.payment?.payment_date ? dayjs(item.payment?.payment_date).format('DD-MM-YYYY') : 'N/A'}</Td>
                            <Td>{item.property?.name}</Td>
                            <Td>{item.tenant?.user?.name}</Td>
                            <Td>{item.deposit_amount}</Td>
                            <Td>
                              <span style={{ color: '#369708' }}> {item.payment?.status}</span>
                               <InfoIcon onClick={(e) => handleClickForPaid(e, item)} className='ms-2' />
                              <Popover
                                className='popup_block_refund'
                                id={id}
                                open={showInfo && item?.id === id}
                                anchorEl={anchorElPaid}
                                onClose={handleCloseForPaid}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                              >
                                <Typography sx={{ p: 2 }} className='popContent'>
                                  <p>Payment Date: {item.payment ? dayjs(item.payment?.payment_date).format(DEFAULT_DATE_FORMAT) : 'N/A'}</p>
                                  <p>{item?.payment?.status === BILL_PAID_STATUS.PAID ? 'UTR No.:' : 'Transfer Id:'} {item.payment ? item.payment?.transaction_id : 'N/A'}</p>
                                </Typography>
                              </Popover>
                            </Td>
                          </Tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </InfiniteScroll>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
