import React, { useMemo } from 'react'
import ReactQuill from 'react-quill'

export default function TextQuill({ name, value, onChange, placeholder, disabled, required }) {
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
        ["bold", "italic", "underline"],
        [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],
      ],
    }
  }), []);
  return (
    <ReactQuill
      className='mb-2 w-100'
      name={name}
      value={value ?? ''}
      onChange={onChange}
      placeholder={placeholder}
      readOnly={disabled}
      required={required}
      modules={modules}
      style={{
        ...(disabled &&
          { cursor: 'not-allowed' })
      }}
    />)
}
