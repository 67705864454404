export const ColumnRentSlip =(isSortable)=>([
  {
    column_name: "Floor",
    key_name: "floor_no",
    sort_enabled: isSortable?true:false,
  },
  {
    column_name: "Flat",
    key_name: "flat_no",
    sort_enabled: isSortable?true:false,
  },
  {
    column_name: "Room",
    key_name: "room_no",
    sort_enabled: isSortable?true:false,
  },

  {
    column_name: "Tenant Name",
    key_name: "name",
    sort_enabled: false,
  },
  {
    column_name: "Electric Charge  (in INR)",
    key_name: "electricity_amount",
    sort_enabled: isSortable?true:false,
  },
  {
    column_name: "Other Charges  (in INR)",
    key_name: "other_amount",
    sort_enabled: isSortable?true:false,
  },
  {
    column_name: "Rent (in INR)",
    key_name: "rent",
    sort_enabled: false,
  },
  {
    column_name: "Total Amount (in INR)",
    key_name: "total_amount",
    sort_enabled: isSortable?true:false,
  },
  {
    column_name: "Payment Status",
    key_name: "status",
    sort_enabled: isSortable?true:false,
  },
  {
    column_name: "Note",
    key_name: "note",
    sort_enabled: false,
  },
]);
