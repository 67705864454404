import React, { useEffect, useState } from 'react';
import { Button, InputGroup } from 'reactstrap';
import GlobalInnerHeader from './globalInnerHeader';
import { viewByIdApi, editApi } from '../../api/GlobalSettings/api';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Spinner } from 'reactstrap'
function Agreement({ tabDetails }) {
  const { id } = tabDetails;

  const [agreementPayload, setAgreementPayload] = useState({})
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isDataModified, setIsDataModified] = useState(false);
  const [savedValue, setSavedValue] = useState(null);

  async function validateInput(value) {
    try {
      await Yup.number().required('Agreement Amount is required').typeError(' Agreement Amount must be a number').positive('Agreement Amount must be positive value').validate(value);
      setError('');
      setIsDataModified(true);
    } catch (err) {
      setError(err.message);
    }
  }

  async function counterIncrease(e) {

    if (parseInt(agreementPayload?.value) >= 0) {
      setAgreementPayload(prev => ({
        ...prev,
        value: parseInt(prev.value) + 1
      }

      ));

      const value = parseInt(agreementPayload?.value) + 1
      await validateInput(value)

    }

  }

  async function counterDecrease(e) {

    if (parseInt(agreementPayload?.value) > 0) {

      const value = parseInt(agreementPayload?.value) - 1
      setAgreementPayload(prev => ({
        ...prev,
        value: parseInt(prev.value) - 1
      }

      ));
      await validateInput(value)

    }

  }

  async function onDataAttributeChange(e) {
    let value = e.target.value
    if (isNaN(value)) value = ''

    setAgreementPayload(prev => ({
      ...prev,
      value

    }));
    await validateInput(value);
  }

  async function getAgreementDetails() {
    try {
      const response = await viewByIdApi(id);
      const { payload } = response;
      setAgreementPayload(payload)
      setSavedValue(payload.value);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAgreementDetails();
  }, [])

  async function saveData() {
    try {
      setIsLoading(true)
      const payloadDetails = {
        id,
        payload: { payload: agreementPayload }
      }
      await editApi(payloadDetails);
      setSavedValue(agreementPayload.value);
      setIsDataModified(false);
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="tabInnerContent">
      <GlobalInnerHeader title="Agreement & Police Verification" />
      <div className="settingBody settings_holder mt-4">
        <span>Charges applied for agreement & police verification </span>
        <InputGroup>
          <div className="input-group-append">
            <Button type="button" color="primary" onClick={(e) => { counterDecrease(e) }} disabled={isLoading || !tabDetails.isEditable}>
              <i className="mdi mdi-minus" />
            </Button>
          </div>
          <input
            type="text"
            className="form-control"
            value={agreementPayload?.value ?? ''}
            placeholder="Enter Amount"
            onChange={(e) => onDataAttributeChange(e)}
            disabled={isLoading}

          />
          <div className="input-group-append">
            <Button type="button" color="primary" onClick={(e) => { counterIncrease(e) }}
              disabled={isLoading || !tabDetails.isEditable}>
              <i className="mdi mdi-plus" />
            </Button>
          </div>
        </InputGroup>
        <span>INR.</span>
        {error && <div className='text-danger mt-2'>{error}</div>}
        <div className="text-end mt-4">
          {tabDetails.isEditable &&
            <Button
              disabled={!isDataModified || isLoading || agreementPayload.value == savedValue || error}
              color="primary"
              onClick={saveData}>{isLoading && <Spinner size="sm" color="light" />}
              Save
            </Button>}
        </div>
      </div>
    </div>
  );
}

Agreement.propTypes = {
  tabDetails: PropTypes.any
}
export default Agreement;
