import React, { useState, useEffect, useCallback } from "react";
import {
    PersonOutlineOutlined,
    LocalPhoneOutlined,
    CloseOutlined,
    HotelOutlined,
    CancelOutlined,
    PersonAddAltOutlined,
    Info
} from '@mui/icons-material';
import Photo from "../../../assets/images/photo-icon.svg";
import { Button } from 'reactstrap';
import { TextField, Box, Modal, Tooltip, Popover, Typography } from '@mui/material';
import AmenitiesSlider from "./amenitiesSlider";
import { fetchBedType, selectAmenitiesIcon, selectProfessionIcon } from "../../../common/amenities_icon";
import { toast } from 'react-toastify';
import { pgVacantRequestCreate } from "../../../api/pg-vacant-request";
import ConfirmationModal from "../../Common/ConfirmationModal";
import { ACTIONS, ALL_MODULES, DEFAULT_DATE_FORMAT, OPERATION_TYPE, PROPERTY_STATUS, SOCKET_EVENT } from "../../../common/constant";
import * as Yup from 'yup';
import { useFormik } from 'formik'; import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from "dayjs";
import { AddTenant } from "./addTenant";
import { usePermissionGiven } from "../../Hooks/UserPermission";
import { TenantActionModal } from "../../Common/TenantActionModal";
import { removeTenantFromBed } from "../../../api/property";
import { AsterikLabel } from "../../Common/AsterikLabel";
import { getS3BaseUrl } from "../../../helpers/string_helper";
import { socket } from "../../../utils/socket";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};

const amenitiesStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};

function FloorBedMatrix({ rooms, propertyStatus }) {
    const [tenantOpen, setTenantOpen] = useState(false);
    const [amenityOpen, setAmenityOpen] = useState(false);
    const tenantHandleOpen = () => setTenantOpen(true);
    const tenantHandleClose = () => setTenantOpen(false);
    const [bedDetails, setBedDetails] = useState()
    const amenityHandleOpen = () => setAmenityOpen(true);
    const amenityHandleClose = () => setAmenityOpen(false);
    const [roomData, setRoomData] = useState();
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [isDeleteConfirm, setDeleteConfirm] = useState(false);
    const [isAddTenant, setIsAddTenant] = useState(false);
    const tenantAddHandleClose = () => setIsAddTenant(false);
    const [update, setUpdate] = useState(0);
    const hasTenantAddPermission = usePermissionGiven(ALL_MODULES.PROPERTIES, OPERATION_TYPE.ADD_TENANT);
    const hasTenantRemovePermission = usePermissionGiven(ALL_MODULES.PROPERTIES, OPERATION_TYPE.REMOVE_TENANT);
    const [isTenantRemove, setIsTenantRemove] = useState(false)
    const tenantActionHandleOpen = () => setIsTenantRemove(true);
    const tenantActionHandleClose = () => setIsTenantRemove(false);
    const [action, setAction] = useState();
    const s3BaseUrl = getS3BaseUrl();
    const [isVacantRequest, setIsVacantRequest] = useState(false);
    const { property } = useParams();
    const [socketData, setSocketData] = useState();
    const [cancelBookingData, setCancelBookingData] = useState()
    const [bookingRefresh, setBookingRefresh] = useState();
    const [bookBedRefresh, setBookBedRefresh] = useState()
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const showInfo = Boolean(anchorEl);

    useEffect(() => {
        if (rooms) {
            const roomList = rooms?.rooms.map((room) => {
                if (room.documents?.length > 0) {
                    const result = room.documents.map((item) => {
                        return {
                            name: item.name,
                            location: `${s3BaseUrl}${item?.location}`
                        };
                    });
                    return {
                        ...room,
                        documents: result
                    }
                } else {
                    return room
                }
            })
            setRoomData({ ...rooms, rooms: roomList })
        }
    }, [rooms])

    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENT.BED_BOOKING_EMIT, (data) => {
                console.log('new data', data)
                if ((!socketData || !(socketData?.property_id === parseInt(property) && socketData?.room_id === data?.room_id && socketData?.bed_id === data?.bed_id)) && data?.property_id === parseInt(property) && propertyStatus === PROPERTY_STATUS.LIVE) {
                    setSocketData(data)
                }
            })
            socket.on(SOCKET_EVENT.BED_BOOKING_REFRESH_EMIT, (data) => {
                console.log('bed refresh data', data)
                if ((!bookingRefresh || bookingRefresh?.bed?.id !== data?.bed?.id) && data?.property_id === parseInt(property) && propertyStatus === PROPERTY_STATUS.LIVE) {
                    setBookingRefresh(data)
                    const roomFilter = rooms?.rooms?.find((item) => item?.id === data?.room_id)
                    const bedData = roomFilter?.beds?.find((bed) => bed?.id === data?.bed?.id)
                    if (roomFilter && bedData) {
                        updateBedData(data)
                    }
                }
            })
            socket.on(SOCKET_EVENT.CANCEL_BOOKING_EMIT, (data) => {
                console.log('cancel data', data, cancelBookingData)
                if ((!cancelBookingData || !(cancelBookingData?.property_id === parseInt(property) && cancelBookingData?.room_id === data?.room_id && cancelBookingData?.bed_id === data?.bed_id)) && data?.property_id === parseInt(property) && propertyStatus === PROPERTY_STATUS.LIVE) {
                    setCancelBookingData(data)
                    const roomFilter = rooms?.rooms?.find((item) => item?.id === data?.room_id)
                    const bedData = roomFilter?.beds?.find((bed) => bed?.id === data?.bed_id)
                    if (roomFilter && bedData) {
                        console.log('updated data', bedData)
                        updateBedData({
                            ...bedData,
                            ...(bedData?.vacant_requests?.length > 0 ? {
                                advance_booking_user: null
                            } : {
                                user: null,
                            }),
                            booking_date: null,
                            room_id: data?.room_id
                        })
                    }
                }
            })

            socket.on(SOCKET_EVENT.BED_BOOKING_REFRESH_EMIT, (data) => {
                if ((!bookBedRefresh || bookBedRefresh?.bed?.id !== data?.bed?.id) && (data?.property_id === parseInt(property))) {
                    setBookBedRefresh(data)
                    const roomFilter = rooms?.rooms?.find((item) => item?.id === data?.bed?.room_id)
                    const bedData = roomFilter?.beds?.find((bed) => bed?.id === data?.bed?.id)
                    if (roomFilter && bedData) {
                        updateBedData(data?.bed?.user ? { ...data?.bed, bed_id: data?.bed?.id, vacant_requests: bedData?.vacant_requests } : { ...bedData, is_temporarily: false, expiry_at: null, bed_id: data?.bed?.id, room_id: data?.bed?.room_id })
                    }
                }
            })
        }
    }, [socket])

    const refreshBed = useCallback(
        debounce((data) => {
            updateBedData(data, 'refresh')
        }, 900000),
        []
    );

    const vacantRequestCreateForTenant = async () => {
        try {
            const payload = {
                leaving_date: dayjs(formik.values.leaving_date).format('YYYY-MM-DD'),
                ...(formik.values.note && {
                    note: {
                        description: formik.values.note
                    }
                }),
                tenant_id: bedDetails.tenant_id,
                bed_id: bedDetails.bed_id
            }

            const response = await pgVacantRequestCreate(payload)
            if (response.status === 200) {
                if (propertyStatus === PROPERTY_STATUS.LIVE) {
                    socket.emit(SOCKET_EVENT.PG_VACANT_SEND, { ...response.data.data, property_id: parseInt(property) })
                }

                const result = await roomData?.rooms?.map((room) => {
                    if (room.id === response.data.data.bed.room.id) {
                        const updatedBed = room?.beds.map((bed) => {
                            if (bed.id === response?.data.data?.bed_id) {
                                return {
                                    ...bed,
                                    vacant_requests: [{
                                        leaving_date: response.data.data.leaving_date,
                                        note: response.data.data.note,
                                        tenant_id: response.data.data.tenant_id,
                                    }]
                                }
                            }
                            else {
                                return bed
                            }
                        })
                        return {
                            ...room,
                            beds: updatedBed
                        }
                    }
                    else {
                        return room
                    }
                })
                setRoomData({ ...rooms, rooms: result });
                setUpdate(update + 1);
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message)
            }

            setDeleteConfirm(false);
        } catch (err) {
            toast.error(err.response.data.message);
            setDeleteConfirm(false);
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            leaving_date: "",
            note: ''
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            leaving_date: Yup.date().typeError("Given date is invalid type").required('Please enter leaving date'),
            note: Yup.string().required()
        }),
    });

    const updateBedData = (bedData, type = '') => {
        const mapData = roomData ? roomData : rooms
        const result = mapData?.rooms?.map((room) => {
            if (room.id === bedData?.room_id) {
                const updatedBed = room?.beds.map((bed) => {
                    if (bed.id === bedData?.id) {
                        return {
                            ...(type === 'refresh' ? (bed?.vacant_requests?.length > 0 ? true : !bed?.user) && {
                                ...bedData
                            } : {
                                ...bedData
                            }),
                        }
                    }
                    else {
                        return bed
                    }
                })
                console.log('updated bed', updatedBed)
                return {
                    ...room,
                    beds: updatedBed
                }
            }
            else {
                return room
            }
        })
        setRoomData({ ...roomData, rooms: result });
    }

    const refresh = async (bedData) => {
        tenantAddHandleClose();
        if (propertyStatus === PROPERTY_STATUS.LIVE) {
            socket.emit(SOCKET_EVENT.ADD_TENANT_SEND, { bedData, property_id: parseInt(property), status: propertyStatus })
        }

        await updateBedData(bedData)
        setUpdate(update + 1);
    }

    useEffect(() => {
        if (socketData?.property_id === parseInt(property) && propertyStatus === PROPERTY_STATUS.LIVE) {
            const roomFilter = rooms?.rooms?.find((item) => item?.id === socketData?.room_id)
            const bedData = roomFilter?.beds?.find((bed) => bed?.id === socketData?.bed_id)
            if (roomFilter && bedData) {
                console.log('bed update', bedData)
                updateBedData({ ...bedData, is_temporarily: true, expiry_at: socketData?.expiry_at, room_id: socketData?.room_id })
                refreshBed({ ...bedData, bed_id: bedData?.id, is_temporarily: false, room_id: socketData?.room_id, expiry_at: null })
            }
        }
    }, [socketData])

    const handleTenantAction = (data) => {
        tenantActionHandleClose()
        if (data === 'Vacant Request Creation') {
            tenantHandleOpen()
        } else {
            setAction(ACTIONS.DELETE)
            setDeleteConfirm(true)
        }
    }

    const handleRemoveTenant = async () => {
        try {
            const response = await removeTenantFromBed(bedDetails.bed_id, { user_id: bedDetails.user_id })
            if (response.status === 200) {
                refresh(response.data.data)
                toast.success(response?.data?.message);
            }
        } catch (err) {
            toast.error(err.response.data.message);
        } finally {
            setDeleteConfirm(false);
        }
    }

    return (
        <div className="floorBedMatrix">
            <h6 style={{ fontSize: 'medium' }}> Owner: <PersonOutlineOutlined /> {rooms?.user?.name}  &nbsp; <LocalPhoneOutlined /> {rooms?.user?.contact_number} </h6>
            <div className="mainCardBlock d-flex" key={update}>
                {roomData && roomData?.rooms?.length > 0 && roomData.rooms.map((item, index) => (
                    <div className="card" key={index}>
                        <div className="fbmRoom d-flex align-items-start">
                            <div className="room_ico"><HotelOutlined className="me-2" /></div>
                            <div className="fbmRoomDetail">
                                <h5>{item?.name}</h5>
                                <p>{item?.room_type?.name}, {fetchBedType(item.bed_count)}</p>
                            </div>
                            <Tooltip title="amenities & picture">
                                <img src={Photo} alt={Photo} onClick={() => {
                                    setSelectedAmenities(item.amenities)
                                    setSelectedDocuments(item.documents)
                                    amenityHandleOpen()
                                }} className="cursor-pointer mx-2" />
                            </Tooltip>
                        </div>
                        {item.beds.toSorted((a, b) => a.name > b.name ? 1 : -1).map((bed, index) => (
                            <div className="fbmRoombed d-flex align-items-start justify-content-between" key={index}>
                                <div className="d-flex">
                                    {selectProfessionIcon(bed?.user?.tenant?.type)}
                                    <div className="fbmRoomBedDetail">
                                        <h5>{bed.name}</h5>
                                        <p>Rate: {bed.price}</p>
                                        {(bed.vacant_requests?.length > 0 && bed.vacant_requests[0]?.tenant_id === bed?.user?.tenant?.id) ? (
                                            !dayjs(dayjs(bed.vacant_requests[0]?.leaving_date).format(DEFAULT_DATE_FORMAT)).isBefore(dayjs(dayjs().format(DEFAULT_DATE_FORMAT))) && (
                                                <>
                                                    <p>{bed?.user?.name}</p>
                                                    {bed.vacant_requests?.length > 0 && (
                                                        <p className="text-danger" style={{ fontSize: '11px', marginBottom: '0px', marginTop: '0px' }}> Available from {dayjs(bed.vacant_requests[0]?.leaving_date).add(1, 'day').format('DD-MM-YYYY')}</p>
                                                    )}
                                                </>
                                            )
                                        ) : (
                                            <>
                                                <p>{bed?.user?.name}</p>
                                                {bed.user?.tenant?.joining_date && (
                                                    <p className="text-danger" style={{ fontSize: '11px', marginBottom: '0px', marginTop: '0px' }}> Booked from {dayjs(bed.user?.tenant?.joining_date).format('DD-MM-YYYY')}</p>

                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                {bed?.advance_booking_user && (
                                    <>
                                        <Info onClick={handleClick} className='ms-2' />
                                        <Popover
                                            className='popup_block_refund'
                                            id={bed.id}
                                            open={showInfo}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Typography sx={{ p: 2 }} className='popContent'>
                                                <p>Booked in advanced by {bed?.advance_booking_user?.name}, Date of joining : {dayjs(bed?.advance_booking_user?.tenant?.joining_date).format(DEFAULT_DATE_FORMAT)}</p>
                                            </Typography>
                                        </Popover>
                                    </>
                                )}
                                {(bed?.user && ((bed.vacant_requests?.length > 0 && bed.vacant_requests[0].tenant_id === bed.user.tenant.id ? !dayjs(dayjs(bed.vacant_requests[0]?.leaving_date).format(DEFAULT_DATE_FORMAT)).isBefore(dayjs(dayjs().format(DEFAULT_DATE_FORMAT))) : true) || bed.vacant_requests?.length === 0)) ? (hasTenantRemovePermission &&
                                    <CancelOutlined onClick={() => {
                                        setBedDetails({ tenant_id: bed.user.tenant.id, bed_id: bed?.id, user_id: bed.user.id })
                                        if (bed?.vacant_requests?.length > 0 && bed.vacant_requests[0].tenant_id === bed.user.tenant.id) {
                                            formik.setValues({
                                                leaving_date: dayjs(bed.vacant_requests[0].leaving_date),
                                                note: bed?.vacant_requests[0]?.note?.description
                                            })
                                            setIsVacantRequest(true)
                                            tenantHandleOpen()
                                        } else if (propertyStatus === PROPERTY_STATUS.LIVE) {
                                            tenantHandleOpen()
                                            setIsVacantRequest(false)
                                            formik.resetForm();
                                        } else {
                                            tenantActionHandleOpen();
                                            setIsVacantRequest(false)
                                            formik.resetForm();
                                        }
                                    }} className="cursor-pointer mt-4" />
                                ) : (
                                    hasTenantAddPermission &&
                                    (bed?.is_temporarily && bed?.expiry_at !== null && dayjs(bed?.expiry_at).isAfter(dayjs()) ? (
                                        <p className="ml-8"> Temporarily Unavailable</p>
                                    ) : (<PersonAddAltOutlined onClick={() => {
                                        setBedDetails({ bed_id: bed?.id })
                                        setIsAddTenant(true)
                                    }} className="cursor-pointer" />))
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            {/* Tenant Modal Starts */}
            {tenantOpen && (
                <Modal
                    open={tenantOpen}
                    onClose={tenantHandleClose}
                    disableScrollLock={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"

                >
                    <Box sx={style} className="tenantRemove">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5>Remove Tenant</h5>
                            <CloseOutlined onClick={tenantHandleClose} className="cursor-pointer" />
                        </div>
                        <div className="tenantBody">
                            <div className="mb-3">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            name="leaving_date"
                                            format="DD/MM/YYYY"
                                            label={<div>Date of evacuation <AsterikLabel /></div>}
                                            value={formik?.values?.leaving_date ? formik?.values?.leaving_date : null}
                                            slotProps={{
                                                textField: {
                                                    clearable: true,
                                                    size: 'small', variant: "outlined", onBlur: () => {
                                                        if (!formik.touched?.leaving_date) {
                                                            formik.setFieldTouched("leaving_date", true, false);
                                                        }
                                                    },
                                                    onClear: () => {
                                                        formik.setFieldValue(
                                                            "leaving_date",
                                                            ""
                                                        );
                                                    },
                                                }
                                            }}
                                            className='w-100'
                                            onChange={(e) => {
                                                formik.setFieldValue("leaving_date", e ? dayjs(e) : "");
                                                if (!formik.touched?.leaving_date) {
                                                    formik.setFieldTouched("leaving_date", true, false);
                                                }
                                            }}
                                            //format="MM/DD/YYYY"
                                            required
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                                {formik.touched.leaving_date && formik.errors.leaving_date && (
                                    <span className="text-danger">{formik.errors.leaving_date}</span>
                                )}
                            </div>
                            <TextField
                                fullWidth
                                id="outlined-multiline-static"
                                label={<div>Note <AsterikLabel /></div>}
                                name="note"
                                value={formik.values.note}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                multiline
                                rows={3}
                                className="tenantNoteTextarea"
                            />
                            {formik.touched.note && formik.errors.note && (
                                <span className="text-danger">{formik.errors.note}</span>
                            )}
                        </div>

                        {!isVacantRequest && (
                            <div className="modal_footer text-center mt-3">
                                <Button className="yellow_gradient_btn"
                                    disabled={!formik.isValid || !formik.values.note || !formik.values.leaving_date}
                                    onClick={() => {
                                        tenantHandleClose()
                                        setAction(ACTIONS.CREATE)
                                        setDeleteConfirm(true)
                                    }}
                                >
                                    Submit
                                </Button>
                            </div>
                        )}
                    </Box>
                </Modal>
            )}

            {isDeleteConfirm && (
                <ConfirmationModal
                    action={action}
                    show={isDeleteConfirm}
                    onCloseClick={() => setDeleteConfirm(false)}
                    onAcceptClick={async () => {
                        const isDeleted = action === ACTIONS.DELETE ? await handleRemoveTenant() : await vacantRequestCreateForTenant();
                        if (isDeleted) {
                            setDeleteConfirm(false);
                        }
                    }}
                />
            )}



            {isTenantRemove && (<TenantActionModal isOpen={isTenantRemove} handleClose={tenantActionHandleClose} emitHandleChange={(data) => handleTenantAction(data)} />)}

            {/* Amenity Modal open */}
            {amenityOpen && (
                <Modal
                    open={amenityOpen}
                    onClose={amenityHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"

                >
                    <Box sx={amenitiesStyle} className="tenantRemove">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="crossIcon cursor-pointer position-absolute">
                                <CloseOutlined onClick={amenityHandleClose} />
                            </div>
                        </div>
                        <div className="amenityImagesSlider mb-3">
                            <AmenitiesSlider files={selectedDocuments} />
                        </div>
                        <div className="detail_block amenityCustomBox py-0">
                            <h6 className="block_heading">Amenities</h6>
                            <ul className="custom_amenties_options d-flex flex-wrap ps-0 mb-0" style={{ listStyle: 'none' }}>
                                {(selectedAmenities && selectedAmenities?.length > 0) ? selectedAmenities.map((item, index) => (
                                    <li className="position-relative d-flex" key={index + 1}>
                                        {selectAmenitiesIcon(item?.toLowerCase())} <div className="amenity_item">{item}</div>
                                    </li>
                                )) : 'No amenities are available for this room'}
                            </ul>
                        </div>
                    </Box>
                </Modal>
            )}


            {isAddTenant && (
                <AddTenant isOpen={isAddTenant} handleClose={tenantAddHandleClose} bed_id={bedDetails.bed_id} owner_id={rooms?.user?.id} refresh={refresh} />
            )}
        </div>
    )
}

export default FloorBedMatrix;