import React, { useState, useEffect } from 'react';
import {
  Card, Button,
  Col,
  Row,
} from 'reactstrap';
import {
  Table, Tbody, Tr, Td,
} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'flatpickr/dist/themes/material_blue.css';
import { CircularProgress, Popover, Typography, Menu, MenuItem } from '@mui/material';
import { ACTIONS, ALL_MODULES, DEFAULT_PER_PAGE_VALUE, OPERATION_TYPE } from '../../common/constant';
import NodataFound from '../../components/Common/NoDataFound';
import TableHeaders from '../../components/Common/TableHeaders';
import PaginationComponent from '../../components/Common/PaginationComponent';
import { usePermissionGiven } from '../../components/Hooks/UserPermission';
import AddOrEditOrViewNoteModal from '../../components/Common/AddOrEditOrViewNoteModal';
import { toast } from 'react-toastify';
import { Info, Description } from '@mui/icons-material';
import DeleteModal from '../../components/Common/DeleteModal';
import dayjs from 'dayjs';
import { deleteRefundDepositSlip, getAllRefundDeposit } from '../../api/deposit-refund';
import NoDataIcon from "../../assets/images/no-data.png";
import Search from '../../components/Common/GlobalSearch';
import { DepositRefundFilterDrawer } from '../../components/Finance/DepositRefundFilterDrawer';

export function RefundDeposit() {
  document.title = ' Finance | Dstayz';
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorElPaid, setAnchorElPaid] = useState(null)
  const [id, setId] = useState(null);
  const [update, setUpdate] = useState(0);
  const [page, setPage] = useState(1);
  const [paginationMeta, setPaginationMeta] = useState({});
  const [refundDepositList, setRefundDepositList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE_VALUE);
  const [sort, setSort] = useState();
  const [noteModal, setNoteModal] = useState(false);
  const [filterInputs, setFilterInputs] = useState([])
  const [filterFields, setFilterFields] = useState([])
  const [isDepositRefundFilter, setIsDepositRefundFilter] = useState(false);
  const [searchItem, setSearchItem] = useState()
  const [noteModalActionAndPayload, setNoteModalActionAndPayload] = useState({
    action: null,
    item: null
  })
  const [isLoading, setIsLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleDeleteCloseModal = () => setDeleteOpen(false);
  const hasDeletePermission = usePermissionGiven(ALL_MODULES.FINANCE, OPERATION_TYPE.DELETE)

  useEffect(() => {
    fetchAllRefundDeposit({ page: page, limit: rowsPerPage })
  }, [sort])

  const fetchAllRefundDeposit = async (paginatedData) => {
    try {
      setIsLoading(true);
      const params = {
        ...(sort?.order && { order: sort.order }),
        ...(sort?.direction && { direction: sort.direction }),
        ...paginatedData,
      };
      const response = (await getAllRefundDeposit(params)).data.data;
      setPaginationMeta(response.meta)
      let level = (response.meta.current_page - 1) * response.meta.per_page;
      const resultData = response.data.map((res) => {
        return {
          indexNo: ++level,
          ...res,
        };
      });
      setRefundDepositList(resultData)

    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSort = async (sort) => {
    setRefundDepositList([]);
    setSort(sort);
  };

  const columns = [
    { column_name: `Refund date`, key_name: 'payment_date', sort_enabled: true, style: { minWidth: '140px' } },
    { column_name: `Refunded Amount (in INR)`, key_name: 'deposit_amount', sort_enabled: true, style: { minWidth: '140px' } },
    { column_name: `Property Name`, key_name: 'property_name', sort_enabled: true, style: { minWidth: '140px' } },
    { column_name: `Tenant Name`, key_name: 'tenant_name', sort_enabled: true, style: { minWidth: '140px' } },
    { column_name: 'Note', key_name: 'note', sort_enabled: false, style: { minWidth: '100px' } },
    { column_name: `Refunded By`, key_name: 'refund_by', sort_enabled: true, style: { minWidth: '140px' } },
  ]

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const paginationModelMethod = async (data) => {
    if (data?.page) {
      setPage(data.page)
      fetchAllRefundDeposit({ page: data.page, limit: rowsPerPage })
    } else {
      setUpdate(prev => prev + 1)
      setPage(1)
      setRowsPerPage(data?.limit)
      fetchAllRefundDeposit({ page: data.page, limit: data?.limit })
    }
  };

  const handleNoteModalToggle = (action, item, modalState) => {
    setNoteModalActionAndPayload({
      action,
      item,
      module: ALL_MODULES.FINANCE,
      isDisabled: true,
    })
    setNoteModal(prev => {
      if (modalState) {
        return modalState
      }
      return !prev
    })
  }

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const res = await deleteRefundDepositSlip(id);
      if (res?.status == 200) {
        toast.success(res?.data?.message);
        setPage(1);
        fetchAllRefundDeposit({ page: 1, limit: rowsPerPage });
      }
    } catch (e) {
      setIsLoading(false);
      toast.error(e?.response?.data?.message || e.message);
    } finally {
      setDeleteOpen(false);
      setIsLoading(false);
    }
  };

  const handleClickForPaid = (event) => {
    setAnchorElPaid(event.currentTarget);
  };
  const handleCloseForPaid = () => {
    setAnchorElPaid(null);
  };

  const showInfo = Boolean(anchorElPaid)

  const globalSearch = async (params) => {
    setRefundDepositList([]);
    setSearchItem(params)
    setPage(1)
    setPaginationMeta()
    setUpdate((pre) => pre + 1)
    await fetchAllRefundDeposit({
      ...params,
      limit: params.limit,
      page: params.page,
      ...(params.search && {
        search: params.search,
      }),
      ...(sort?.order && { order: sort.order }),
      ...(sort?.direction && { direction: sort.direction }),
    });
  };

  const handleFilter = async (filterFields, filterInputs) => {
    setRefundDepositList([]);
    setPage(1)
    setPaginationMeta()
    setUpdate((pre) => pre + 1)
    setFilterInputs(filterInputs)
    setFilterFields(filterFields)
    await fetchAllRefundDeposit({
      limit: rowsPerPage,
      ...(searchItem && {
        ...searchItem,
      }),
      filter_fields: filterFields,
      filter_inputs: filterInputs,
      ...(sort?.order && { order: sort.order }),
      ...(sort?.direction && { direction: sort.direction }),
    });
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Refund Deposit</h4>
              </div>
            </div>
          </div>
          <Row>
            <Col className="col-12">
              <div className="flexbox_holder">
                <div className='app-search'>
                  <Search
                    listCall={globalSearch}
                    params={{
                      limit: rowsPerPage,
                      page: 1,
                      order: sort?.order,
                      direction: sort?.direction,
                      filter_fields: filterFields,
                      filter_inputs: filterInputs,
                    }}
                  />
                </div>
                <div>
                  <Button className='yellow_gradient_btn me-2' onClick={() => setIsDepositRefundFilter(true)}>
                    <i className="mdi mdi-filter-variant" />
                    Filter
                  </Button>
                </div>
              </div>
              <Card className="table_card_holder">
                {/* <CardBody> */}
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    {refundDepositList?.length === 0 && isLoading && (
                      <div style={{ textAlign: 'center' }}>
                        <CircularProgress />
                      </div>
                    )}
                    {refundDepositList?.length === 0 && !isLoading && (
                      <div style={{ textAlign: 'center', height: '400px' }}>
                        <NodataFound text="No refund deposit to show" icon={NoDataIcon} />
                      </div>
                    )}

                    {refundDepositList?.length > 0 && (
                      <Table
                        id="tech-companies-1"
                        className="table table-bordered rentSlipTable"
                      >
                        <TableHeaders
                          headers={columns}
                          sortBy={sort}
                          emitSortedColumn={handleSort}
                          module={ALL_MODULES.FINANCE}
                        />
                        <Tbody>
                          {refundDepositList?.map((item, index) => (
                            <Tr key={index}>
                              <Td>{item.indexNo}</Td>
                              <Td> {item.payment?.payment_date ? dayjs(item.payment?.payment_date).format('DD-MM-YYYY') : 'N/A'}</Td>
                              <Td>
                                {item.deposit_amount}
                                <Info onClick={handleClickForPaid} className='ms-2' />
                                <Popover
                                  className='popup_block_info'
                                  id={item.id}
                                  open={showInfo}
                                  anchorEl={anchorElPaid}
                                  onClose={handleCloseForPaid}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                >
                                  <Typography sx={{ p: 1 }} className='popContent'>
                                    <p>Transaction ID: {item.payment ? item.payment?.transaction_id : 'N/A'}</p>
                                  </Typography>
                                </Popover>
                              </Td>
                              <Td>{item.property?.name}</Td>
                              <Td>{item.tenant?.user?.name}</Td>
                              <Td className="d-flex justify-content-center">{item?.note ? (
                                <Description titleAccess='Show Note'
                                  style={{ textAlign: 'center', fontSize: '28px', cursor: 'pointer', color: 'blue' }}
                                  fontSize={'inherit'} color='info' onClick={() => {
                                    setId(item.id)
                                    handleNoteModalToggle(ACTIONS.VIEW, item)
                                  }} />
                              ) : null}</Td>
                              <Td>
                                <span>{item.payment?.creator?.name}</span>
                              </Td>
                              {hasDeletePermission && (
                                <Td className="threeDotsSpace">
                                  <Button
                                    id="basic-button"
                                    aria-controls={open ? "basic-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={(e) => {
                                      setId(item?.id);
                                      handleClick(e);
                                    }}
                                  >
                                    <i className="mdi mdi-dots-vertical font-size-15"></i>
                                  </Button>
                                  <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                      "aria-labelledby": "basic-button",
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() => {
                                        setDeleteOpen(true);
                                        handleClose();
                                      }}
                                    >
                                      <i className="mdi mdi-trash-can-outline font-size-18 align-middle me-1 text-muted" />
                                      Delete
                                    </MenuItem>
                                  </Menu>
                                </Td>
                              )}
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    )}

                    {refundDepositList?.length > 0 && (<div className="numbers_pagination_holder">
                      <PaginationComponent
                        paginationMeta={paginationMeta}
                        handleChangePagination={paginationModelMethod}
                        update={update}
                      >
                      </PaginationComponent>
                    </div>)}
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {
        noteModal && <AddOrEditOrViewNoteModal handleNoteModalToggle={handleNoteModalToggle} noteModalActionAndPayload={noteModalActionAndPayload} />
      }

      {deleteOpen && (
        <DeleteModal
          isLoading={isLoading}
          show={deleteOpen}
          onCloseClick={handleDeleteCloseModal}
          onDeleteClick={() => handleDelete()}
        />
      )}

      {isDepositRefundFilter && (
        <DepositRefundFilterDrawer
          isOpen={isDepositRefundFilter}
          toggleDrawer={() => setIsDepositRefundFilter(!isDepositRefundFilter)}
          emitFilterItem={handleFilter}
          selectedFields={filterFields}
          selectedInputs={filterInputs}
        />
      )}
    </>
  );
}
