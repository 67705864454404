export const ROLES = {
    TENANT: 'Tenant',
    EMPLOYEE: '',
    SUPER_ADMIN: 'Super Admin',
    OWNER: 'Owner',
    ADMIN: 'Admin',
    PROPERTY_MANAGER: 'Property Manager',
    PROSPECTING_OWNER: 'Prospecting Owner',
}

export const ACTIVE_STATUS = {
    ACTIVE: 'Active',
    IN_ACTIVE: 'Inactive'
}

export const NOTIFICATION_EVENTS = {
    PROSPECTING_OWNER_CREATE: 'prospecting_owner_create',
    PROSPECTING_OWNER_CONVERT: 'prospecting_owner_convert',
    OWNER_DELETE: 'owner_delete',
    TENANT_ASSIGNED_TO_BED_BY_ADMIN: 'tenant_assigned_to_bed_by_admin',
    TENANT_BOOKED_BED_BY_SELF: 'tenant_booked_bed_by_self',
    PG_LEAVING_REQUEST_BY_TENANT: 'pg_leaving_request_by_tenant',
    TENANT_EVICTED_BY_ADMIN: 'tenant_evicted_by_admin',
    NEW_PROPERTY_REQUEST_CREATE: 'new_property_request_create',
    NEW_PROPERTY_REQUEST_DELETE: 'new_property_request_delete',  // not implemented
    PROPERTY_ACTIVATE: 'property_create',
    PROPERTY_STATUS_UPDATE: 'property_status_update',
    NEW_VISIT_REQUEST_CREATE: 'new_visit_request_create',
    NEW_COMPLAIN_TICKET_CREATE: 'new_complain_ticket_create',
    COMPLAIN_STATUS_CHANGE: 'complain_status_change',
    MESSAGE_RECEIVED: 'message_received',
    RENT_SLIP_PUBLISHED: 'rent_slip_published',
    RENT_PAYMENT_BY_TENANT: 'rent_payment_by_tenant',
    PAYOUT_RECEIVED: 'payout_received',
    TASK_ASSIGNED: 'task_assigned',
    TASK_STATUS_UPDATE: 'task_status_update', //TESTED 
    PROPERTY_REVIEW_CREATE: 'property_review_create',
    OWNER_REPLY_TO_REVIEW_ADMIN_PORTAL: 'owner_reply_to_review_admin_portal',
    CANCEL_PG_BOOKING: 'cancel_pg_booking',
    TENANT_CREATE_BY_ADMIN: 'tenant_create_by_admin',
}

export const CMS_PAGES = {
    HOME: 'home',
    ENLIST_PROPERTY: 'enlist_property',
    ABOUT_US: 'about_us',
    CONTACT_US: 'contact_us',
    PRIVACY_POLICIES: 'privacy_policies',
    TERMS_AND_CONDITION: 'terms_and_condition',
    CANCEL_POLICY: 'cancel_policy',
    REFUND_POLICY: 'refund_policy',
    GUESTS_POLICY: 'guests_policy',
    SOCIAL_MEDIA: 'social_media',
};