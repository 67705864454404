import React, { useState, useEffect } from 'react';
import { Row } from 'reactstrap';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'flatpickr/dist/themes/material_blue.css';
import { useLocation } from 'react-router-dom';
import VacantRequestTabs, { tabs } from '../../components/PGVacantRequest/VacantRequestTabs';
import { fetchPgVacantCardData } from '../../api/pg-vacant-request';

function PGVacantRequest() {
  document.title = ' PG Vacant Request | Dstayz';
  const [navigateState] = useState(useLocation()?.state);
  const [cardUpdate, setCardUpdate] = useState(0);
  const [statCard, setStatCard] = useState();
  const [selectedTab, setSelectedTab] = useState(tabs.ACTIVE);

  useEffect(() => {
    getAllVacantCardData()
  }, [selectedTab])

  const getAllVacantCardData = async () => {
    try {

      let params = {};
      if (selectedTab === tabs.EXPIRED) {
        params.expired = 1;
      } 

      const response = (await fetchPgVacantCardData(params)).data.data
      setStatCard(response);
      setCardUpdate(cardUpdate + 1)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row className='d-grid' style={{ gridTemplateColumns: '1fr 1fr 1fr' }} key={cardUpdate}>
            <div className="stat_outer_holder">
              <div className="stat_card_holder">
                <div className="flexbox_holder justify-content-start">
                  <div className="icon_holder">
                    <i className="mdi mdi-information-variant" />
                  </div>
                  <h4 className="heading">Total Vacant Request (This Month)</h4>
                </div>
                <h2 className="num_heading">{statCard?.current_month_pg_vacant}</h2>
              </div>
            </div>
            <div className="stat_outer_holder">
              <div className="stat_card_holder">
                <div className="flexbox_holder justify-content-start">
                  <div className="icon_holder">
                    <i className="mdi mdi-information-variant" />
                  </div>
                  <h4 className="heading">Total Vacant Request (Next Month)</h4>
                </div>
                <h2 className="num_heading">{statCard?.next_month_pg_vacant}</h2>
              </div>
            </div>
            <div className="stat_outer_holder">
              <div className="stat_card_holder">
                <div className="flexbox_holder justify-content-start">
                  <div className="icon_holder">
                    <i className="mdi mdi-information-variant" />
                  </div>
                  <h4 className="heading">Vacant Request (Deposit Refund Pending)</h4>
                </div>
                <h2 className="num_heading">{statCard?.pg_vacant_not_refund}</h2>
              </div>
            </div>
          </Row>
          <VacantRequestTabs
            setSelectedTab={setSelectedTab}
            fetchCounterData={getAllVacantCardData}
            navigateState={navigateState}
          />
        </div>
      </div>

    </>
  );
}

export default PGVacantRequest;
