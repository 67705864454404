import React, { useState, useEffect } from "react";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Modal, TextField, Box } from '@mui/material';
import { Button } from 'reactstrap';
import ConfirmationModal from "../../Common/ConfirmationModal";
import { ACTIONS, KNOWN_ROLES, USER_STATUS } from "../../../common/constant";
import * as Yup from 'yup';
import { useFormik } from 'formik'; import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from "dayjs";
import { SearchDropDownListPaginationComponent } from "../../Common/SearchDropDownPaginationList";
import { getAllUser } from "../../../api/user";
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { addTenantToBed } from "../../../api/property";
import { AsterikLabel } from "../../Common/AsterikLabel";
import { getAllGlobalSettings } from "../../../api/globalSettings";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    height: 350,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};

export function AddTenant({ isOpen, handleClose, bed_id, owner_id, refresh }) {
    const [isConfirm, setIsConfirm] = useState(false);
    const [modal, setModal] = useState(true);
    const { property } = useParams();
    const [preBookingDays, setPreBookingDays] = useState()
    const currentDate = dayjs().subtract(1, 'days')

    useEffect(() => {
        fetchGlobalSettings();
    }, [])

    const fetchGlobalSettings = async () => {
        try {
            const response = (await getAllGlobalSettings({ search: 'pre_booking_days' })).data
            const noticePeriodDate = dayjs().add(response.data[0].payload?.value, response.data[0].payload?.unit)
            setPreBookingDays({
                date: noticePeriodDate,
                preBookingValue: response.data[0].payload
            })
        } catch (err) {
            console.log(err)
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            user_id: '',
            joining_date: "",
            deposit_amount: '',
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            user_id: Yup.number().required('Please select tenant'),
            joining_date: Yup.date().typeError("Given date is invalid type")
                .min(dayjs(currentDate), `Your Date of joining should be equal to or after current date ${dayjs().format('DD/MM/YYYY')}.`)
                .max(dayjs(preBookingDays?.date), `Pre booking can be done for maximum ${preBookingDays?.preBookingValue?.value} ${preBookingDays?.preBookingValue?.unit}.`)
                .required('Please enter joining date'),
            deposit_amount: Yup.string().matches(/^[0-9]/, 'Caution Money is number').required('Please enter caution money'),
        }),
    });

    const onClose = () => {
        setModal(false)
    }

    const addTenantInBed = async () => {
        try {
            const payload = {
                user_id: formik.values.user_id,
                joining_date: dayjs(formik.values.joining_date).format('YYYY-MM-DD'),
                property_id: parseInt(property),
                owner_id: owner_id,
                deposit_amount: parseInt(formik.values.deposit_amount)
            }

            const response = await addTenantToBed(bed_id, payload)
            if (response.status === 200) {
                toast.success(response.data.message);
                refresh(response.data.data);
            } else {
                handleClose();
                toast.error(response.data.message);
            }

            setIsConfirm(false);
        } catch (err) {
            toast.error(err.response.data.message);
            handleClose();
            setIsConfirm(false);
        }
    }

    return (
        <div>
            {modal && (
                <Modal
                    open={isOpen}
                    disableScrollLock={true}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"

                >
                    <Box sx={style} className="tenantRemove">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5>Add Tenant</h5>
                            <CloseOutlinedIcon onClick={handleClose} className="cursor-pointer" />
                        </div>
                        <div className="tenantBody">
                            <div className="mb-2">
                                <SearchDropDownListPaginationComponent
                                    label={<div>Select Tenant <AsterikLabel /> </div>}
                                    searchEnabled={true}
                                    listCall={getAllUser}
                                    name='user_id'
                                    onBlur={() => { formik.setFieldTouched('user_id', true) }}
                                    emitItem={(newValue) => {
                                        formik.setFieldValue('user_id', newValue?.id ? newValue?.id : "");
                                        if (!newValue && !formik.touched.user_id) {
                                            formik.setFieldTouched('user_id', true)
                                        }
                                    }}
                                    apiParams={{
                                        filter_fields: ['role', 'status', 'bed'],
                                        filter_inputs: [KNOWN_ROLES.TENANT, USER_STATUS.ACTIVE, 'test'],
                                    }}
                                />
                                <div>
                                    {formik.touched?.user_id && formik.errors?.user_id && (
                                        <span className="text-danger">{formik.errors?.user_id}</span>
                                    )}
                                </div>
                            </div>
                            <div className="mb-3">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            name="joining_date"
                                            format="DD/MM/YYYY"
                                            label={<div>Date of Joining <AsterikLabel /></div>}
                                            slotProps={{
                                                textField: {
                                                    clearable: true,
                                                    size: 'small', variant: "outlined", onBlur: () => {
                                                        if (!formik.touched?.joining_date) {
                                                            formik.setFieldTouched("joining_date", true, false);
                                                        }
                                                    },
                                                    onClear: () => {
                                                        formik.setFieldValue(
                                                            "joining_date",
                                                            ""
                                                        );
                                                    },
                                                }
                                            }}
                                            className='w-100'
                                            onChange={(e) => {
                                                formik.setFieldValue("joining_date", e ? dayjs(e).format('MM/DD/YYYY') : "");
                                                if (!formik.touched?.joining_date) {
                                                    formik.setFieldTouched("joining_date", true, false);
                                                }
                                            }}
                                            required
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                                {formik.touched.joining_date && formik.errors.joining_date && (
                                    <span className="text-danger">{formik.errors.joining_date}</span>
                                )}
                            </div>
                            <div className="mb-4">
                                <TextField
                                    fullWidth
                                    label={<div>Caution Money <AsterikLabel /></div>}
                                    name="deposit_amount"
                                    //key={update}
                                    type="text"
                                    // disabled={isDisabled}
                                    value={formik.values.deposit_amount}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    size="small"
                                    className="w-100"
                                />
                                {formik.touched.deposit_amount && formik.errors.deposit_amount && (
                                    <span className="text-danger">{formik.errors.deposit_amount}</span>
                                )}
                            </div>
                        </div>

                        <div className="modal_footer text-center">
                            <Button className="yellow_gradient_btn"
                                disabled={!formik.isValid}
                                onClick={() => {
                                    onClose()
                                    setIsConfirm(true)
                                }}
                            >
                                Submit
                            </Button>
                        </div>
                    </Box>
                </Modal>
            )}
            <ConfirmationModal
                action={ACTIONS.CREATE}
                show={isConfirm}
                onCloseClick={() => setIsConfirm(false)}
                onAcceptClick={addTenantInBed}
            />
        </div>
    )
}