import React from 'react';
import {
  Row, Form, Offcanvas, OffcanvasHeader, OffcanvasBody,
} from 'reactstrap';
import { fetchBedType } from '../../common/amenities_icon';
import dayjs from 'dayjs';
import { BILL_PAID_STATUS, DEFAULT_DATE_FORMAT, PAYMENT_MODE } from '../../common/constant';


export function ViewCancelledBookingDrawer({ isOpen, toggleDrawer, selectedRow }) {
  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => toggleDrawer(!isOpen)}
    >
      <OffcanvasHeader toggle={toggleDrawer}>
        View Cancelled Booking Details
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="tenantDetailsDrawer">
            <Row className="mb-3">
              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Property Name:</div>
                <div className="tenantDetailDesc">{selectedRow?.property?.name ?? 'N/A'}</div>
              </div>

              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Tenant Name:</div>
                <div className="tenantDetailDesc">{selectedRow?.tenant?.user?.name ?? 'N/A'}</div>
              </div>

              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Floor No:</div>
                <div className="tenantDetailDesc">{selectedRow.floor?.name ?? 'N/A'}</div>
              </div>
              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Flat No:</div>
                <div className="tenantDetailDesc">{selectedRow.flat?.name ?? 'N/A'}</div>
              </div>
              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Room No:</div>
                <div className="tenantDetailDesc">{selectedRow.room?.name ?? 'N/A'}</div>
              </div>

              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Room Type:</div>
                <div className="tenantDetailDesc">{selectedRow.room?.room_type?.name ?? 'N/A'}</div>
              </div>

              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Bed No:</div>
                <div className="tenantDetailDesc">{selectedRow.bed?.name ?? 'N/A'}</div>
              </div>

              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Bed Type:</div>
                <div className="tenantDetailDesc">{fetchBedType(selectedRow.room?.bed_count) ?? 'N/A'}</div>
              </div>
              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Date of Booking:</div>
                <div className="tenantDetailDesc">{dayjs(selectedRow?.booking_date).format(DEFAULT_DATE_FORMAT) ?? 'N/A'}</div>
              </div>
              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Date of cancellation:</div>
                <div className="tenantDetailDesc">{dayjs(selectedRow?.cancelled_date).format(DEFAULT_DATE_FORMAT) ?? 'N/A'}</div>
              </div>
              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Refund Amount (in INR):</div>
                <div className="tenantDetailDesc">{selectedRow?.payment?.total_amount ?? 'N/A'}</div>
              </div>
              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Refund Status:</div>
                <div className="tenantDetailDesc">{selectedRow?.payment?.status ?? 'N/A'}</div>
              </div>
              <Row className="mb-3">
                  <div className="col-sm-12 d-flex align-items-center custom_border_bottom pb-3">
                    <div className="tenantDetailTitle tenantDetFullTitle"> {selectedRow?.payment.status === BILL_PAID_STATUS.PAID ? 'UTR No.:' : 'Transfer Id:'}</div>
                    <div className="tenantDetailDesc">{selectedRow?.payment?.transaction_id ?? 'N/A'}</div>
                  </div>
                </Row>
              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Refund Mode:</div>
                <div className="tenantDetailDesc">{selectedRow?.payment?.payment_mode?.toUpperCase() ?? 'N/A'}</div>
              </div>

              {selectedRow.payment?.payment_mode === PAYMENT_MODE.UPI && (
                <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                  <div className='tenantDetailTitle'>UPI ID :</div>
                  <div className='tenantDetailDesc'>
                    {selectedRow?.payment?.upi_id ?? 'N/A'}
                  </div>
                </div>
              )}

              {selectedRow?.note?.description &&
                <Row className="mb-3">
                  <div className="col-sm-12 d-flex align-items-center custom_border_bottom pb-3">
                    <div className="tenantDetailTitle tenantDetFullTitle">Cancel Reason:</div>
                    <div className="tenantDetailDesc">{selectedRow?.note?.description}</div>
                  </div>
                </Row>}

              {selectedRow?.payment?.payment_mode === PAYMENT_MODE.BANK && selectedRow?.tenant?.bank_detail && (
                <div className='col-12 mt-4'>
                  <div className='bankAccountDet'>
                    <h5 className='mb-3'>Bank Account Details</h5>
                  </div>
                  <Row>
                    <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-2'>
                      <div className='tenantDetailTitle'>Bank Name :</div>
                      <div className='tenantDetailDesc'>
                        {selectedRow?.tenant?.bank_detail?.bank_name ?? 'N/A'}
                      </div>
                    </div>

                    <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-2'>
                      <div className='tenantDetailTitle'>IFSC :</div>
                      <div className='tenantDetailDesc'>
                        {selectedRow?.tenant?.bank_detail?.ifsc ?? 'N/A'}
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-2'>
                      <div className='tenantDetailTitle'>Account Name :</div>
                      <div className='tenantDetailDesc'>
                        {selectedRow?.tenant?.bank_detail?.account_holder_name ?? 'N/A'}
                      </div>
                    </div>

                    <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-2'>
                      <div className='tenantDetailTitle'>Account Number :</div>
                      <div className='tenantDetailDesc'>
                        {selectedRow?.tenant?.bank_detail?.account_number ?? 'N/A'}
                      </div>
                    </div>
                  </Row>
                </div>
              )}
            </Row>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
}
