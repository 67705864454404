import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink,
  TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { CMS_PAGES } from '../../../constants/variables';
import { viewByIdApi } from '../../../api/GlobalSettings/api';
import HeaderImageContent from './HeaderImageContent';
import EnlistProperty from './EnlistProperty';
import Home from './Home';
import ContactUs from './ContactUs';
import SocialMediaLinks from './SocialMediaLinks';

function CMS({ tabDetails }) {
  const [activeTab, setactiveTab] = useState(CMS_PAGES.HOME);
  const [cmsPayload, setCMSPayload] = useState({});

  async function getCMSDetails() {
    try {
      const response = await viewByIdApi(tabDetails.id);
      const { payload } = response;
      setCMSPayload(payload);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCMSDetails();
  }, [])

  const getPage = (page) => {
    switch (page) {
      case CMS_PAGES.HOME:
        return <Home page={page} tabDetails={cmsPayload[page]} refresh={getCMSDetails} />
      case CMS_PAGES.ENLIST_PROPERTY:
        return <EnlistProperty page={page} tabDetails={cmsPayload[page]} refresh={getCMSDetails} />;
      case CMS_PAGES.ABOUT_US:
        return <HeaderImageContent page={page} tabDetails={cmsPayload[page]} refresh={getCMSDetails} />;
      case CMS_PAGES.CONTACT_US:
        return <ContactUs page={page} tabDetails={cmsPayload[page]} refresh={getCMSDetails} />;
      case CMS_PAGES.PRIVACY_POLICIES:
        return <HeaderImageContent page={page} tabDetails={cmsPayload[page]} refresh={getCMSDetails} />;
      case CMS_PAGES.TERMS_AND_CONDITION:
        return <HeaderImageContent page={page} tabDetails={cmsPayload[page]} refresh={getCMSDetails} />;
      case CMS_PAGES.CANCEL_POLICY:
        return <HeaderImageContent page={page} tabDetails={cmsPayload[page]} refresh={getCMSDetails} />;
      case CMS_PAGES.REFUND_POLICY:
        return <HeaderImageContent page={page} tabDetails={cmsPayload[page]} refresh={getCMSDetails} />;
      case CMS_PAGES.GUESTS_POLICY:
        return <HeaderImageContent page={page} tabDetails={cmsPayload[page]} refresh={getCMSDetails} />;
      case CMS_PAGES.SOCIAL_MEDIA:
        return <SocialMediaLinks page={page} tabDetails={cmsPayload[page]} refresh={getCMSDetails} />;

      default:
        break;
    }
  }

  return (
    <div className="tabInnerContent">
      <div className="settingBody settings_holder cms_content_holder mt-1">
        <Nav pills>
          {cmsPayload && Object.keys(cmsPayload).map((key, index) =>
          (<NavItem
            key={index}
          >
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classnames({
                active: activeTab === key,
              })}
              onClick={() => {
                setactiveTab(key);
              }}
            >
              <span>{cmsPayload[key].display_name}</span>
            </NavLink>
          </NavItem>))}
        </Nav>

        <TabContent
          activeTab={activeTab}
          className="p-3 text-muted"
        >
          {Object.keys(cmsPayload)?.length ? Object.values(CMS_PAGES).map((page) => {
            return (<TabPane key={page} tabId={page}>
              {getPage(page)}
            </TabPane>)
          }) : ''}
        </TabContent>
      </div>
    </div>
  );
}

export default CMS;
