import React, { useState } from 'react';
import {
  Button,
  Form,
  Input,
} from 'reactstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as Yup from 'yup';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileUpload from '../../../assets/images/icons/image-add-line.svg'
import { IconButton, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { editCMS } from '../../../api/GlobalSettings/api';
import ImageQuill from './ImageQuill';
import TextQuill from './TextQuill';
import uuid from 'react-native-uuid';
import { formatBytes, getS3BaseUrl } from '../../../helpers/string_helper';
import { DOCUMENT_HASH_BASE_KEYS } from '../../../common/constant';
import { fileUploads } from '../../../api/compay-employee';

export default function EnlistProperty({ page, tabDetails, refresh }) {
  const [isLoading, setIsLoading] = useState(false);
  const [enableField, setEnabledField] = useState({});
  const [bannerImage, setBannerImage] = useState(null);
  const s3BaseUrl = getS3BaseUrl();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      header: tabDetails?.header,
      content: tabDetails?.content,
      form_header: tabDetails?.form_header,
      banner_image_url: tabDetails?.banner_image_url,
      banner_image_url_size: tabDetails?.banner_image_url_size,
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      header: Yup.string().required('header is required'),
      form_header: Yup.string().required('header is required'),
      content: Yup.string().required('content  is required'),
      banner_image_url: Yup.string().nullable(),
      banner_image_url_size: Yup.number()
        .positive()
        .max(50 * 1024 * 1024, 'The documents size must not exceed 50 MiB.'),
    }),
    onSubmit: async (payload) => {
      try {
        setIsLoading(true)
        // Banner image Upload to s3
        if (bannerImage) {
          const imageFile = new FormData();
          imageFile.append('files', bannerImage);
          imageFile.append('base_key', DOCUMENT_HASH_BASE_KEYS.CMS);
          const result = await fileUploads(imageFile);
          if (result?.success) {
            payload.banner_image_url = result?.response?.data?.data?.responses[0]?.actual_location;
          } else {
            return false;
          }
        }
        const success = await editCMS({ page, ...payload });
        if (success)
          refresh();
      } catch (error) {
        console.log(error);
      }
      finally {
        setIsLoading(false)
      }
    },
  });

  const addBannerImage = (file) => {
    let imageFile = file.target.files[0];
    if (imageFile) {
      Object.assign(imageFile, {
        uniqueId: uuid.v4(),
        location: URL.createObjectURL(imageFile),
        formattedSize: formatBytes(imageFile.size),
      });
      setBannerImage(imageFile);
      formik.setValues({
        banner_image_url: imageFile?.location,
        banner_image_url_size: imageFile?.size,
        ...formik.values,
      });
    }
  };

  return (
    <>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}>              {/* Main Image */}
        <div className='d-flex justify-content-between align-items-center'>
          <div className='custom-image-upload' style={{ alignItems: 'center' }}>
            <div className='image-placeholder-employee image-placeholder' style={{height: 'auto', maxHeight: '70px'}}>
              <img
                src={
                  bannerImage
                    ? bannerImage.location
                    : formik?.values?.banner_image_url
                      ? `${s3BaseUrl}${formik?.values?.banner_image_url}`
                      : FileUpload
                }
                alt='Not Found'
              />
            </div>
            <div className='upload_picture'>
              <Input
                className='form-control pos-rel'
                accept='image/*'
                type='file'
                id={`${page}_banner_image_url`}
                name='banner_image_url'
                style={{ display: 'none' }}
                onClick={formik.handleBlur}
                onChange={addBannerImage}
                invalid={
                  !!(
                    formik.touched.banner_image_url &&
                    formik.errors.banner_image_url
                  )
                }
              />
              <label
                style={{
                  alignSelf: 'center',
                }}
                htmlFor={`${page}_banner_image_url`}
                className='custom-file-label mb-0 mx-2'>
                Upload Banner Image
              </label>
            </div>

            {formik.touched.banner_image_url &&
              formik.errors.banner_image_url ? (
              <span className='text-danger-formik'>
                {' '}
                {formik.errors.banner_image_url}
                {formik.errors.banner_image_url_size}
              </span>
            ) : (
              ''
            )}
          </div>
          <Button
            className="yellow_gradient_btn"
            type='submit'
            disabled={!formik.isValid || isLoading}
          >Save</Button>
        </div>

        {/* Header */}
        <div className='my-3'>
          <h4 style={{ fontWeight: '600', fontSize: '16px' }}>
            Header
          </h4>
          <div className='d-flex align-items-center'>
            <TextField
              name='header'
              className='mb-2'
              value={formik.values.header}
              onChange={formik.handleChange}
              required
              disabled={!enableField[`header`]}
              label='Header'
              fullWidth
              type="text"
              onBlur={formik.handleBlur}
              variant="outlined"
              size="small"
              error={
                !!(formik?.touched?.header && formik?.errors?.header)
              }
              helperText={formik.errors.header}
            />
            <div className='d-flex flex-column'>
              <IconButton
                size='small'
                variant='contained'
                onClick={(prev) => {
                  setEnabledField({ ...prev, ['header']: !enableField['header'] })
                }}
              >
                {!enableField['header'] ?
                  <EditOutlinedIcon />
                  : <CancelOutlinedIcon />
                }
              </IconButton>
            </div>
          </div>
        </div>

        {/* Form Header */}
        <h4 style={{ fontWeight: '600', fontSize: '16px' }}>
          Form Header
        </h4>
        <div className='d-flex align-items-center'>
          <TextQuill
            name='form_header'
            value={formik.values?.form_header || ''}
            onChange={(value) => formik.setFieldValue('form_header', value)}
            placeholder='Enter your message'
            disabled={!enableField['form_header']}
            className='mb-2'
            style={{ height: '130px' }}
            required
          />
          <div className='d-flex flex-column'>
            <IconButton
              size='small'
              variant='contained'
              onClick={(prev) => {
                setEnabledField({ ...prev, ['form_header']: !enableField['form_header'] })
              }}
            >
              {!enableField['form_header'] ?
                <EditOutlinedIcon />
                : <CancelOutlinedIcon />
              }
            </IconButton>
          </div>
        </div>

        {/* Content */}
        <h4 style={{ fontWeight: '600', fontSize: '16px' }}>
          Content
        </h4>
        <div className='d-flex align-items-center'>
          <ImageQuill
            name='content'
            value={formik.values.content || ''}
            onChange={(value) => formik.setFieldValue('content', value)}
            placeholder='Enter your message'
            disabled={!enableField['content']}
            className='mb-2'
            style={{ height: '130px' }}
            required
          />
          <div className='d-flex flex-column'>
            <IconButton
              size='small'
              variant='contained'
              onClick={(prev) => {
                setEnabledField({ ...prev, ['content']: !enableField['content'] })
              }}
            >
              {!enableField['content'] ?
                <EditOutlinedIcon />
                : <CancelOutlinedIcon />
              }
            </IconButton>
          </div>
        </div>

        {/* <hr className="cstm_hr" /> */}
        <div className="text-end mt-5">
          <Button
            className="yellow_gradient_btn"
            onClick={formik.handleSubmit}
            disabled={!formik.isValid || isLoading}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
}
