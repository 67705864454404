import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Row, Col, Card, CardBody, Button, Dropdown as DropdownReactStrap, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';

import {
    Table, Tbody, Tr, Td,
} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'flatpickr/dist/themes/material_blue.css';
import { getAllProperties, updatePropertyStatus } from '../../api/property';
import AddressComponent from '../../components/Common/Address';
import { toast } from 'react-toastify';
import SelectBox from '../../components/ActiveProperties/SelectBox';
import PaginationComponent from '../../components/Common/PaginationComponent';
import { ALL_MODULES, DEFAULT_PER_PAGE_VALUE, OPERATION_TYPE, PROPERTY_STATUS, PROPERTY_TYPES, SOCKET_EVENT } from '../../common/constant';
import Search from '../../components/Common/GlobalSearch';
import CircularProgress from '@mui/material/CircularProgress';
import NodataFound from '../../components/Common/NoDataFound';
import PropertyFilter from '../../components/ActiveProperties/PropertyFilter';
import TableHeaders from '../../components/Common/TableHeaders';
import { MenuButton } from '@mui/base/MenuButton';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { grey } from '@mui/material/colors';
import { MenuItem } from '@mui/base/MenuItem';
import { styled } from '@mui/system';
import SettingsIcon from '@mui/icons-material/Settings';
import NearToModal from '../../components/ActiveProperties/NearToModal';
import { usePermissionGiven } from '../../components/Hooks/UserPermission';
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import UploadDocumentModal from '../../components/ActiveProperties/uploadDocumentModal';
import { getS3BaseUrl } from '../../helpers/string_helper';
import NoDataIcon from "../../assets/images/no-data.png";
import PropertyPrefModal from '../../components/ActiveProperties/PropertyPrefModal';
import { getAllGlobalSettings } from '../../api/globalSettings';
import { socket } from '../../utils/socket';
import CautionMoneyModal from '../../components/ActiveProperties/CautionMoneyModal';

const Listbox = styled('ul')(
    ({ theme }) => `
          font-size: 16px;
          padding: 10px;
          margin: 12px 0;
          min-width: 220px;
          border-radius: 5px;
          outline: 0px;
          background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
          border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
          color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
          box-shadow: 0px 4px 6px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
        };
          z-index: 99;
          position: absolute;
          top: 100%;
          right: 0;
          list-style-type: none;
        `,
);

const ListboxItem = styled('li')(
    `padding: '10px'`
)

const StyledMenuItem = styled(MenuItem)`
      && {
        padding: 10px;
        /* Add more styles as needed */
      }
    `;

function ActiveProperties() {
    document.title = ' Active Properties | Dstayz';

    const [menu, setMenu] = useState(false);
    const [foodDocumentData, setFoodDocumentData] = useState();
    const [isOpenPropertyFilterModal, setIsOpenPropertyFilterModal] = useState(false)
    const [isOpenNearToModal, setOpenNearToModal] = useState(false);
    const s3BaseUrl = getS3BaseUrl()
    const navigate = useNavigate();
    const hasEditPermission = usePermissionGiven(ALL_MODULES.PROPERTIES, OPERATION_TYPE.UPDATE);
    const hasDeletePermission = usePermissionGiven(ALL_MODULES.PROPERTIES, OPERATION_TYPE.DELETE);
    const [addFoodServiceModal, setAddFoodServiceModal] = useState(false);
    const [isOpenPropertyPrefModal, setOpenPropertyPrefModal] = useState(false);
    const [isCustomOrder, setIsCustomOrder] = useState(false)
    const locationState = useLocation().state;

    const AddActiveProperty = () => {
        navigate("/active-properties/add-active-properties")
    };

    // request filter drawer
    const togglePropertyFilter = () => {
        setIsOpenPropertyFilterModal(!isOpenPropertyFilterModal);
    };

    const [isLoading, setIsLoading] = useState(false)
    const [paginationMeta, setPaginationMeta] = useState({})
    const [propertyList, setPropertyList] = useState([])
    const [propertyId, setPropertyId] = useState()
    const [update, setUpdate] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE_VALUE)
    const paramsObject = useRef({
        page: 1,
        limit: DEFAULT_PER_PAGE_VALUE,
        filter_fields: ['status'],
        filter_inputs: [`${PROPERTY_STATUS.ACTIVE},${PROPERTY_STATUS.LIVE}`]
    })

    const fetchActiveAndLiveProperties = async (params) => {
        try {
            setIsLoading(true)
            params = {
                ...params,
                filter_fields: params ? ((params.filter_fields && params.filter_fields.includes('status')) ? params.filter_fields : ['status', params.filter_fields]) : ['status'],
                filter_inputs: params ? ((params.filter_fields && params.filter_fields.includes('status')) ? params.filter_inputs : ['Active,Live', params.filter_inputs]) : ['Active,Live'],
            }

            const response = (await getAllProperties(params)).data.data
            setPaginationMeta(response.meta)
            let level = (response.meta.current_page - 1) * response.meta.per_page;
            const resultData = response.data.map((res) => {
                return {
                    id: res.id,
                    index_no: ++level,
                    name: res.name,
                    address: res.address ? <AddressComponent address={res.address} /> : 'N/A',
                    owner_name: res.user?.name ?? 'N/A',
                    owner_contact_no: res.user?.contact_number ?? 'N/A',
                    total_bed: res.bed_count ?? 0,
                    status: res.status,
                    near_to: res?.near_to ?? 'N/A',
                    type: res.type,
                    vacant_bed_count: res.vacant_bed_count,
                    food_menu_documents: res?.food_menu_documents?.map((item) => {
                        return {
                            id: item.id,
                            name: item.name,
                            location: `${s3BaseUrl}${item?.location}`
                        };
                    })
                };
            });
            setPropertyList(resultData)
        } catch (error) {
            toast.error(error.response.data.message)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (locationState) {
            navigate(`/active-properties/${locationState?.property_id}/${locationState?.type === PROPERTY_TYPES.APARTMENT ? 'bhk' : 'room'}`, {
                state: {
                    event: locationState?.event,
                }
            })
        }
        fetchActiveAndLiveProperties({ page: 1, limit: rowsPerPage })
        fetchGlobalSettings()
    }, [])

    const handleStatusChange = async (item, status) => {
        try {
            const res = await updatePropertyStatus(item.id, { status: status.value })
            if (res.status === 200) {
                socket.emit(SOCKET_EVENT.PROPERTY_STATUS_SEND, { data: { property_id: item?.id, status } })
                await fetchActiveAndLiveProperties(paramsObject.current)
                toast.success(res.data.message)
            } else {
                toast.error(res.data.message)
            }
        } catch (e) {
            toast.error(e.response.data.message)
        }
    }

    const paginationModelMethod = async (data) => {
        if (data?.page) {
            paramsObject.current = {
                ...paramsObject.current,
                page: data.page,
                limit: rowsPerPage
            };
            await fetchActiveAndLiveProperties(paramsObject.current);
        } else {
            setUpdate(prev => prev + 1);
            setRowsPerPage(data?.limit);
            paramsObject.current = {
                ...paramsObject.current,
                page: 1,
                limit: data?.limit
            };
            await fetchActiveAndLiveProperties(paramsObject.current);
        }
    };

    const fetchGlobalSettings = async () => {
        try {
            setIsLoading(true)
            const response = (await getAllGlobalSettings({ search: 'property_list_sorting_preference' })).data
            setIsCustomOrder(response.data[0].payload.order_by === 'custom_order')
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    const globalSearch = async (params) => {
        setPaginationMeta()
        setUpdate((pre) => pre + 1)
        if (!params.search || params.search === '') {
            delete paramsObject.current['search']
            delete params['search']
        }
        paramsObject.current = {
            ...paramsObject.current,
            ...params
        }
        await fetchActiveAndLiveProperties(paramsObject.current)
    };

    const handlePropertyFilter = async (params) => {
        paramsObject.current = { ...paramsObject.current, limit: rowsPerPage, page: 1, ...params }
        await fetchActiveAndLiveProperties(paramsObject.current)
    }

    const columns = [
        { column_name: 'Property Name', key_name: 'name', sort_enabled: true, style: { minWidth: '130px' } },
        { column_name: 'Address', key_name: 'address', sort_enabled: false, style: { minWidth: '210px' } },
        { column_name: 'Property Owner', key_name: 'owner_name', sort_enabled: true, style: { minWidth: '145px' } },
        { column_name: 'Owners Phone No.', key_name: 'owner_phone_number', sort_enabled: true, style: { minWidth: '170px' } },
        { column_name: 'Total Bed', key_name: 'bed_count', sort_enabled: true, style: { minWidth: '125px' } },
        { column_name: 'Total Vacant Bed', key_name: 'vacant_bed_count', sort_enabled: true, style: { minWidth: '125px' } },
        { column_name: 'Near To', key_name: 'near_to', sort_enabled: false, style: { minWidth: '145px' } },
        { column_name: 'Status', key_name: 'status', sort_enabled: true }
    ]

    const toggleNearToModal = () => {
        setOpenNearToModal(!isOpenNearToModal);
    }
    const togglePropertyPrefModal = () => {
        setOpenPropertyPrefModal(!isOpenPropertyPrefModal);
    }
    const [modal, setModal] = useState(false);

    const toggleModal = () => {
        setModal(!modal);
    };

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0">Live & Active Property</h4>
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col className="col-12">
                            <div className="flexbox_holder">
                                <div className='app-search'>
                                    <Search
                                        listCall={globalSearch}
                                        params={{ ...paramsObject.current, limit: rowsPerPage, page: 1, order: paramsObject.current.order, direction: paramsObject.current.direction }}
                                    />
                                </div>
                                <div>
                                    <DropdownReactStrap
                                        isOpen={menu}
                                        toggle={() => setMenu(!menu)}
                                        className="d-inline-block"
                                    >
                                        <DropdownToggle className="me-2"><SettingsIcon style={{ height: '20px' }} /></DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem className="cursor-pointer" onClick={toggleNearToModal}> Near To </DropdownItem>
                                            <DropdownItem className="cursor-pointer" disabled={!isCustomOrder} onClick={togglePropertyPrefModal}>Property Re-ordering</DropdownItem>
                                        </DropdownMenu>

                                    </DropdownReactStrap>
                                    <Button
                                        className='yellow_gradient_btn me-2'
                                        onClick={togglePropertyFilter}>
                                        <i className='mdi mdi-filter-variant' />
                                        Filter
                                    </Button>
                                    {usePermissionGiven(ALL_MODULES.PROPERTIES, OPERATION_TYPE.CREATE) && (
                                        <Button color='primary' className="me-2" onClick={AddActiveProperty} >
                                            <i className="mdi mdi-plus" />
                                            Add Property
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <Card className="table_card_holder mt-2">
                                <CardBody>
                                    <div className="table-rep-plugin">
                                        <div
                                            className="table-responsive mb-0"
                                            data-pattern="priority-columns"
                                        >
                                            {isLoading && (
                                                <div style={{ textAlign: 'center', height: '500px' }}>
                                                    <CircularProgress />
                                                </div>
                                            )}
                                            {propertyList?.length === 0 && !isLoading && (
                                                <div style={{ textAlign: 'center', height: '500px' }}>
                                                    <NodataFound text="No property to show." icon={NoDataIcon} />
                                                </div>
                                            )}
                                            {propertyList?.length !== 0 && !isLoading && (
                                                <Table
                                                    id="tech-companies-1"
                                                    className="table table-bordered activePropertiesListing"
                                                >
                                                    <TableHeaders
                                                        headers={columns}
                                                        sortBy={{ order: paramsObject.current.order, direction: paramsObject.current.direction }}
                                                        module={ALL_MODULES.PROPERTIES}
                                                        emitSortedColumn={async (sort) => {
                                                            if (sort.direction) {
                                                                paramsObject.current = {
                                                                    ...paramsObject.current,
                                                                    ...sort
                                                                }
                                                            } else {
                                                                delete paramsObject.current['order']
                                                                delete paramsObject.current['direction']
                                                            }
                                                            await fetchActiveAndLiveProperties(paramsObject.current)
                                                        }}
                                                    />
                                                    <Tbody>
                                                        {propertyList && propertyList?.map((item, index) => (
                                                            <Tr key={index}>
                                                                <Td>{item.index_no}</Td>
                                                                <Td className="cursor_pointer" onClick={() => {
                                                                    navigate(`/active-properties/${item.id}/${item.type === PROPERTY_TYPES.APARTMENT ? 'bhk' : 'room'}`)
                                                                }
                                                                }>{item.name}</Td>
                                                                <Td>{item.address}</Td>
                                                                <Td>{item.owner_name}</Td>
                                                                <Td>{item.owner_contact_no}</Td>
                                                                <Td>{item.total_bed}</Td>
                                                                <Td>{item.vacant_bed_count}</Td>
                                                                <Td>{item.near_to}</Td>
                                                                <Td><SelectBox hasEditPermission={hasEditPermission} item={item} handleChange={handleStatusChange} /></Td>
                                                                <Td className="threeDotsSpace">
                                                                    {(hasDeletePermission || hasEditPermission) && (
                                                                        <Dropdown>
                                                                            <MenuButton><i className='mdi mdi-dots-vertical font-size-15'></i></MenuButton>
                                                                            <Menu slots={{ listbox: Listbox }}>
                                                                                {hasEditPermission && (
                                                                                    <>
                                                                                        <StyledMenuItem className="cursor-pointer" slots={{ ListboxItem: ListboxItem }} onClick={() => {
                                                                                            setFoodDocumentData({ propertyId: item.id, documents: item?.food_menu_documents, status: item?.status })
                                                                                            setAddFoodServiceModal(true)
                                                                                        }}>
                                                                                            {item?.food_menu_documents?.length > 0 ? (
                                                                                                <span><i className="mdi mdi-pencil-outline font-size-18 align-middle text-muted me-1" />Edit Food Service</span>
                                                                                            ) : (
                                                                                                <span><PostAddOutlinedIcon />Add Food Service</span>)}
                                                                                        </StyledMenuItem>
                                                                                        {item.status === PROPERTY_STATUS.ACTIVE && (
                                                                                            <StyledMenuItem className="cursor-pointer" slots={{ ListboxItem: ListboxItem }} onClick={() => navigate(`/active-properties/edit/${item.id}`)}>
                                                                                                <i className="mdi mdi-pencil-outline font-size-18 align-middle text-muted me-1" />
                                                                                                Edit
                                                                                            </StyledMenuItem>
                                                                                        )}
                                                                                        <StyledMenuItem className="cursor-pointer" onClick={() => {
                                                                                            setPropertyId({ id: item?.id, status: item?.status })
                                                                                            toggleModal()
                                                                                        }}>
                                                                                                <span><i className="mdi mdi-cash font-size-18 align-middle text-muted me-1" />Set Caution Money</span>
                                                                                        </StyledMenuItem>
                                                                                    </>
                                                                                )}
                                                                            </Menu>
                                                                        </Dropdown>
                                                                    )}
                                                                </Td>
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                            )}
                                        </div>
                                    </div>

                                    {propertyList?.length > 0 && (<div className="numbers_pagination_holder">
                                        <PaginationComponent
                                            paginationMeta={paginationMeta}
                                            handleChangePagination={paginationModelMethod}
                                            update={update}
                                        />
                                    </div>)}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            {isOpenPropertyFilterModal && (
                <PropertyFilter
                    isOpen={isOpenPropertyFilterModal}
                    toggleDrawer={togglePropertyFilter}
                    emitFilterItem={(e) => {
                        handlePropertyFilter(e)
                    }}
                />
            )}
            {isOpenNearToModal && (<NearToModal isNearToOpen={isOpenNearToModal} isNearTotoggle={toggleNearToModal} />)}
            {isOpenPropertyPrefModal && (<PropertyPrefModal isPropertyPrefOpen={isOpenPropertyPrefModal} isPropertyPreftoggle={togglePropertyPrefModal} />)}

            {addFoodServiceModal && (
                <UploadDocumentModal
                    open={addFoodServiceModal}
                    handleClose={() => setAddFoodServiceModal(false)}
                    propertyId={foodDocumentData?.propertyId}
                    status={foodDocumentData?.status}
                    documents={foodDocumentData?.documents}
                    refresh={() => fetchActiveAndLiveProperties({ page: 1, limit: rowsPerPage })}
                />
            )}

            {modal && (
                <CautionMoneyModal 
                    open={modal}
                    handleClose={() => setModal(false)}
                    propertyId={propertyId}
                />
            )}
        </>
    );
}

export default ActiveProperties;
