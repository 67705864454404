import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody } from 'reactstrap';
import CountUp from 'react-countup';

function MiniWidget(props) {
  return (
    <>
      {props?.reports?.map((report, key) => (
        <div className="shadow_card dash_single_card" key={key}>
          <Card>
            <CardBody>
              {/* <div className="float-end mt-2">
                <ReactApexChart
                  options={report.options}
                  series={report.series}
                  type={report.charttype}
                  height={report.chartheight}
                  width={report.chartwidth}
                />
              </div> */}
              <p className="text-muted mb-0" style={{ fontSize: '12px' }}>
                <div className='dash_counter d-flex'>
                  <span className="dash_card_icon" style={{ color: report?.color, backgroundColor: report.backgroundColor }}>
                    <i className={`${report?.icon}`} />
                  </span>
                  <span className={`${report?.prefix === 'Tenant’s Open Complain:' ? 'owner_complain_text' : 'tenant_complain_text'}`}><CountUp end={report?.value} separator="," prefix={report.prefix} suffix={report.suffix} decimals={report.decimal} /></span>
                </div>
                <div>
                  <p className={`${report?.prefix === 'Tenant’s Open Complain:' ? 'owner_complain_text' : ''} text-muted mb-0`}>{report?.title}</p>
                </div>
              </p>
            </CardBody>
          </Card>
        </div>
      ))}
    </>
  );
}

export default MiniWidget;

MiniWidget.propTypes = {
  reports: PropTypes.array,
};
